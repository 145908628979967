import  request  from '@utils/request'

export async function getChannelList(data) {
  return request('api/v1/channel', {
    method: 'GET',
    data,
  })
}

export async function createChannel(data) {
  return request(`api/v1/channel`, {
    method: 'POST',
    data,
  })
}

export async function updateChannel(id, data) {
  return request(`api/v1/channel/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function deleteChannel(id) {
  return request(`api/v1/channel/${id}`, {
    method: 'DELETE',
  })
}

import React, { useState } from 'react'
import { Modal } from 'antd'
import FunTableTransfer from '@components/FunTableTransfer'
import CharacterList from '@pages/CharacterList'

const CharacterTransfer = (props) => {
  const { dataList, onSubmit, onCancel, visible, confirmLoading } = props
  const [selectedList, setSelectedList] = useState(dataList)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'character_id',
      width: 50,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 200,
    },
  ]

  return (
    <Modal
      width="80%"
      bodyStyle={{
        padding: '32px 40px 48px',
      }}
      destroyOnClose
      title="关联人物"
      visible={visible}
      onOk={() => onSubmit(selectedList)}
      onCancel={() => onCancel()}
      confirmLoading={confirmLoading}
    >
      <FunTableTransfer
        rowKey="id"
        columns={columns}
        dataList={dataList}
        onChange={(list) => {
          setSelectedList(list)
        }}
      >
        <CharacterList hideHeader />
      </FunTableTransfer>
    </Modal>
  )
}

export default CharacterTransfer

import React from "react";
import App from "../App";
import Page404 from "../pages/404";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useRoutes,
  HashRouter,
} from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import Login from "../pages/user/Login";
// import { createHashHistory } from 'history'
import customRoutes from "./routes";
// const history = createHashHistory()

// class RouterConfig extends React.Component {
//   render() {
//     return (
//       <Router history={history}>
//         <Routes>
//             <Route path="/" element={<App />}>
//                 <Route index element={<Login/>}/>
//                 <Route path='login' element={<Login/>}/>
//             </Route>
//         </Routes>
//         <Routes>
//             <Route path='mediaList' element={<MediaList/>}/>
//             <Route path='episodeList' element={<EpisodeList/>}/>
//             <Route path='characterList' element={<CharacterList/>}/>
//             <Route path='userList' element={<UserList/>}/>
//             <Route path='404' element={<Page404/>}/>
//             <Route path="*" element={<Navigate to="404" />} />
//         </Routes>

//       </Router>
//     )
//   }
// }
// export default RouterConfig
const MyRouter = () => {
  let element = useRoutes([
    { path: "login", element: <Login /> },
    {
      path: "/",
      element: <BaseLayout />,
      children: [...customRoutes],
    },
    // Not found routes work as you'd expect
    { path: "*", element: <Page404 /> },
  ]);

  // The returned element will render the entire element
  // hierarchy with all the appropriate context it needs
  return element;
};
const RouterConfig = () => {
  return (
    <HashRouter>
      {console.log("routerConfig location", window.location.pathname)}
      <MyRouter />
    </HashRouter>
  );
};
export default RouterConfig;

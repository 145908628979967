import React, { useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
    channelData,
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? "编辑用户" : "新建用户";
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        release_date: moment(data.release_date * 1000),
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        if (values.aword && values.aword.length < 10) {
          message.warning("一句话简介至少10个字");
          return;
        }
        if (values.description && values.description.length > 20000) {
          message.warning("剧情描述字数最大为两万～");
          return;
        }
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item name="question_id" label="题目id">
          <Input placeholder="请输入题目ID！" type="number" />
        </Form.Item>
        <Form.Item
          name="name"
          label="名称"
          rules={[{ required: true, message: "请输入名称！" }]}
        >
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item label="频道" name="channel_id">
          <Select>
            {channelData.length &&
              channelData.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="aword" label="一句话简介">
          <Input placeholder="请输入一句话简介" maxLength="255" />
        </Form.Item>
        <Form.Item label="海报">
          <Form.Item name="poster">
            <Input placeholder="请输入海报" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.poster}
            onUploaded={(url) => {
              console.log("onUploaded", url);
              form.setFieldsValue({
                poster: url,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="剧照">
          <Form.Item
            name="still"
            rules={[{ required: false, message: "请输入剧照！" }]}
          >
            <Input placeholder="请输入剧照" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.still}
            onUploaded={(url) => {
              form.setFieldsValue({
                still: url,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="剧情描述"
          rules={[{ required: true, message: "请输入剧情描述！" }]}
        >
          <Input placeholder="请输入剧情描述" />
        </Form.Item>
        <Form.Item
          name="directors"
          label="导演"
          rules={[{ required: false, message: "请输入导演！" }]}
        >
          <Input placeholder="请输入导演" />
        </Form.Item>
        <Form.Item
          name="actors"
          label="演员"
          rules={[{ required: false, message: "请输入演员！" }]}
        >
          <Input placeholder="请输入演员" />
        </Form.Item>
        <Form.Item
          name="score"
          label="评分"
          rules={[{ required: false, message: "请输入评分！" }]}
        >
          <Input placeholder="请输入评分" />
        </Form.Item>
        <Form.Item
          name="release_date"
          label="上映日期"
          rules={[{ required: false, message: "请输入上映日期！" }]}
        >
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

import React, { useState, useCallback } from 'react'
import { Modal, Table, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './index.less'

const FunTransfer = (props) => {
  const { children, rowKey, columns, dataList, onChange } = props
  console.log('FunTransfer props', props)
  const [selectedList, setSelectedList] = useState(dataList || []) // 右侧数据列表
  const [leftList, setLeftList] = useState([]) // 左侧已选列表
  const [rightList, setRightList] = useState([]) // 右侧已选列表

  const leftRowSelection = {
    fixed: true,
    selectedRowKeys: leftList.map((ele) => {
      if (!ele.character_id) ele.character_id = ele.id
      return ele.id
    }),
    onChange: (rowKeys, rows) => {
      setLeftList(rows)
    },
  }

  const rightRowSelection = {
    fixed: true,
    selectedRowKeys: rightList.map((ele) => {
      return ele.id
    }),
    onChange: (rowKeys, rows) => {
      setRightList(rows)
    },
  }

  const contentTable = () => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    const childrenWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      // console.log(children, child)
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { tableOptions: { rowSelection: leftRowSelection } })
      }
      return child
    })
    return childrenWithProps
  }

  function onSelect() {
    const list = [...selectedList]
    const selectedIds = []
    list.forEach((item) => {
      selectedIds.push(item.character_id)
    })
    leftList.forEach((ele) => {
      if (!selectedIds.includes(ele.character_id)) {
        list.push(ele)
      }
    })
    setSelectedList(list)
    // console.log('onSelect', list)
    onChange(list)
    setLeftList([])
  }

  function onDelete() {
    const list = selectedList.filter((item) => {
      return !rightList.some((deleteItem) => {
        return deleteItem.id === item.id
      })
    })
    setSelectedList(list)
    // console.log('onDelete', list)
    onChange(list)
  }

  return (
    <div className={styles['table-list-transfer']}>
      <div className={styles['table-list']}>{contentTable()}</div>
      <div className={styles['transfer-controller']}>
        <div>
          <Button icon={<LeftOutlined />} onClick={onDelete} />
        </div>
        <div>
          <Button icon={<RightOutlined />} onClick={onSelect} />
        </div>
      </div>
      <div className={styles['selected-list']}>
        <Table
          rowKey={rowKey}
          dataSource={selectedList}
          columns={columns}
          rowSelection={rightRowSelection}
          // scroll={{ x: 'max-content', y: 'calc(100% - 55px)' }}
          size="small"
          pagination={false}
        />
      </div>
    </div>
  )
}

export default FunTransfer

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
    partyList,
    channelList
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? "编辑秘密" : "新建秘密";
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        release_date: moment(data.release_date * 1000),
        //将party_map字符串转换为数组，并且每一项都转为数字
        party_map: data.party_map.split(",").map(item => Number(item))
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;



  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        if(values.party_map){
          values.party_map = values.party_map.join(",");
        }
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        {data?.id&&<Form.Item name="id" label="媒体id">
          <Input disabled={data?.id} placeholder="请输入媒体ID！" type="number" />
        </Form.Item>}
        <Form.Item name="channel_id" label="频道"  rules={[{ required: true, message: "必须选择频道！" }]}>
          <Select>
            {channelList.length &&
              channelList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="名称"
          rules={[{ required: true, message: "请输入名称！" }]}
        >
          <Input placeholder="请输入名称" minLength={1} maxLength={30} />
        </Form.Item>
        <Form.Item name="aword" label="一句话简介"rules={[{ required: true, message: "必须输入简介！" }]}>
          <Input placeholder="请输入一句话简介" maxLength="32" />
        </Form.Item>
        <Form.Item name="answer" label="答案"rules={[{ required: true, message: "必须输入答案！" }]}>
          <Input placeholder="请输入答案" maxLength="255" />
        </Form.Item>
        <Form.Item name="order_num" label="排序数值"rules={[{ required: true, message: "必须输入答案！" }]}>
          <Input placeholder="请输入数值"  type={'number'}  maxLength={30}/>
        </Form.Item>
        <Form.Item label="应用类型" name="party_map">
          {/* value 值是每一项的id用逗号拼接 */}
          <Select
            mode="multiple"
            allowClear
            placeholder="请选择应用类型"
            normalize={(value, prevValue) => {
              if (value.length === 0) {
                return prevValue;
              }
              return value.join(",");
            }
            }
            >
            {partyList.length &&
              partyList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item label="海报">
          <Form.Item name="poster">
            <Input placeholder="请输入海报" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.poster}
            onUploaded={(url) => {
              console.log("onUploaded", url);
              form.setFieldsValue({
                poster: url,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="剧照">
          <Form.Item
            name="still"
            rules={[{ required: false, message: "请输入剧照！" }]}
          >
            <Input placeholder="请输入剧照" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.still}
            onUploaded={(url) => {
              form.setFieldsValue({
                still: url,
              });
            }}
          />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

import React, { useState, useEffect, useRef } from 'react'
import { Table } from 'antd'
// import styles from './index.less'

const BasicTable = (props) => {
  const [scroll, setScroll] = useState({ x: 'max-content' })
  const { dataList, pagination, handleChange, columns , loading} = props
  const wrapper = useRef(null)
  useEffect(() => {
    const setTableHeight = () => {
      // console.log(wrapper.current)
      if (!wrapper.current) return
      let boxer = document.getElementsByClassName('ant-layout-content')[0].offsetHeight - document.getElementsByClassName('ant-page-header')[0].offsetHeight - 32

      const tbody = wrapper.current.getElementsByClassName('ant-table-tbody')[0]
      const thead = wrapper.current.getElementsByClassName('ant-table-thead')[0]
      const pagination = wrapper.current.getElementsByClassName('ant-pagination')[0]

      let headerHeight = thead ? thead.offsetHeight : 0
      let paginationHeight = pagination ? pagination.offsetHeight : 0

      // console.log('wrapper.current.offsetHeight',wrapper.current.offsetHeight)
      // console.log('wrapper.current.offsetHeight - headerHeight - paginationHeight',wrapper.current.offsetHeight - headerHeight - paginationHeight)
      // console.log('maxHeight minHeight',maxHeight,minHeight)
      if (wrapper.current.offsetHeight - headerHeight - paginationHeight >= tbody.offsetHeight) {
        setScroll({ x: 'max-content' })
      } else {
        // console.log(wrapper.current.offsetHeight - headerHeight - paginationHeight)
        setScroll({
          x: 'max-content',
          y: wrapper.current.offsetHeight - headerHeight - paginationHeight - 35
        })
      }
    }

    setTableHeight()

    window.addEventListener('resize', setTableHeight)

    return () => {
      window.removeEventListener('resize', setTableHeight)
    }
  }, [dataList])

  return (
    <div ref={wrapper} className="table-wrapper">
      <Table
        dataSource={dataList}
        rowKey="id"
        columns={columns}
        scroll={scroll}
        scrollToFirstRowOnChange
        // rowSelection={rowSelection}
        size="small"
        pagination={pagination}
        onChange={handleChange}
        bordered
        loading={loading}
        {...props}
      />
    </div>
  )
}
export default BasicTable

const commonData = {
    partyList : [
        {
            id: 1,
            name: '管理后台',
        },
        {
            id: 3,
            name: '小程序',
        },
        {
            id: 4,
            name: '影视趣答',
        },
        {
            id: 5,
            name: '剧情社',
        },
        {
            id: 6,
            name: '星星知道',
        },
    ]
}
export default commonData;
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Button,
  Modal,
  Drawer,
  Divider,
  Descriptions,
  Tag,
  Image,
  Tooltip,
  Input,
  Form,
  Row,
  Col,
  Select,
  Upload,
  message,
  DatePicker,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import EpisodeList from "@pages/EpisodeList";
import UpdateForm from "./components/UpdateForm";
import CharacterTransfer from "./components/CharacterTransfer";
import FileUploader from "@/components/FileUploader";
import useTag from "@/hooks/useTag";
import useTable from "@/hooks/useTable";
import { formatParams, formatChannelTag } from "@/utils/util";
import moment from "moment";
import {
  getMediaList,
  getMedia,
  createMedia,
  updateMedia,
  deleteMedia,
  getMediaCharacter,
  updateMediaCharacter,
  exportMediaData,
  crawlBaiduData,
} from "@/services/media";
import { getChannelList } from "@/services/channel";
import { getAppList } from '@/services/app'
import commonData from "@/common/commonData";

const { confirm } = Modal;

const MediaList = () => {
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [characterModalVisible, setCharacterModalVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [detail, setDetail] = useState(undefined);
  const [showDetail, setShowDetail] = useState(false);
  const [detailLoading, setDetailLoading] = useState({});
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [baiduVisible, setBaiduVisible] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [channelTagData, setChannelTagData] = useState([]);
  // const [partyList, setPartyList] = useState([])

  const { tagRender } = useTag();
  const {partyList} = commonData
  // const channelTagRender = useTag(channelTagData);
  const sourceTagRender = useTag([
    {
      key: 1,
      text: "百度百科",
      color: "green",
    },
    // {
    //   key: 2,
    //   text: "豆瓣",
    //   color: "blue",
    // },
  ]);
  const [form] = Form.useForm();
  const [baidufrom] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (!channelData) return;
    getChannelData();
  }, []);
  useEffect(() => {
    if (!channelData) return;
    let channelTagData = channelData;
    channelTagData = formatChannelTag(channelData);
    setChannelTagData(channelTagData);
    console.log("channelTagData", channelTagData);
  }, [channelData]);

  const columns = [
    {
      title: "媒体ID",
      dataIndex: "id",
      fixed: "left",
      width: 80,
    },
    {
      title: "题目ID",
      dataIndex: "question_id",
      fixed: "left",
      width: 80,
    },
    {
      title: "名称",
      dataIndex: "name",
      fixed: "left",
    },
    {
      title: "频道",
      dataIndex: "channel_id",
      width: 100,
      render: (text) => {
        if (channelTagData.length) {
          let obj = channelTagData.find((item) => {
            return item.id === text;
          });
          if (obj) return <Tag color={obj.color}>{obj.text}</Tag>;
        }
        return <Tag color="#f50">未知</Tag>;
      },
    },
    {
      title: '应用类型',
      dataIndex: 'party_map',
      width: 200,
      render: (text, record) => {
        // party_map 是partyList 的id逗号拼接的字符串
        //将record.party_map转换成数组,然后遍历数组,拼接出对应的名称  
        let partyArr = record.party_map.split(',')
        let partyName = partyArr.map(item=>{
            let partyObj = partyList.find(item2=>{
                return item2.id == item
            })
            return partyObj?.name
          }
        )
        return partyName.join(',')
      }
    },
    {
      title: "一句话简介",
      dataIndex: "aword",
      width: 200,
    },
    {
      title: "海报",
      dataIndex: "poster",
      width: 100,
      render: (text) => {
        return <Image width={50} src={text} />;
      },
    },
    {
      title: "剧照",
      dataIndex: "still",
      width: 100,
      render: (text) => {
        return <Image width={50} src={text} />;
      },
    },
    {
      title: "描述",
      dataIndex: "description",
      width: 200,
      // ellipsis: {
      //   showTitle: false,
      // },
      // render: (address) => (
      //   <Tooltip placement="topLeft" title={address}>
      //     {address}
      //   </Tooltip>
      // ),
    },
    {
      title: "导演",
      dataIndex: "directors",
      width: 150,
    },
    {
      title: "演员",
      dataIndex: "actors",
      width: 300,
    },
    {
      title: "评分",
      dataIndex: "score",
      width: 80,
    },
    {
      title: "状态",
      dataIndex: "disable",
      width: 100,
      render: (text) => {
        return tagRender(text);
      },
    },
    // {
    //   title: "来源",
    //   dataIndex: "source",
    //   width: 100,
    //   render: (text) => {
    //     // return sourceTagRender.tagRender(text);
    //     return "";
    //   },
    // },
    {
      title: "上映时间",
      dataIndex: "release_date",
      width: 200,
      render: (text) => {
        return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "更新时间",
      dataIndex: "update_time",
      width: 200,
      render: (text) => {
        return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "操作",
      dataIndex: "option",
      fixed: "right",
      width: 300,
      valueType: "option",
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            loading={detailLoading[`data${record.id}`]}
            disabled={Object.keys(detailLoading).some(
              (key) => detailLoading[key]
            )}
            onClick={() => {
              setDetailLoading((detailLoading) => {
                const result = {
                  ...detailLoading,
                };
                result[`data${record.id}`] = true;
                return result;
              });
              setCurrentRow(record);
              Promise.all([
                getMedia(record.id),
                getMediaCharacter({
                  media_id: record.id,
                  page: 1,
                  perpage: 1000,
                }),
              ]).then((result) => {
                setDetail({
                  ...result[0],
                  characterList: result[1].list,
                });
                setShowDetail(true);
                setDetailLoading((detailLoading) => {
                  const result = {
                    ...detailLoading,
                  };
                  result[`data${record.id}`] = false;
                  return result;
                }); //['成功了', 'success']
              });
            }}
          >
            详情
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record);
              setUpdateModalVisible(true);
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record);
              getMediaCharacter({
                media_id: record.id,
                page: 1,
                perpage: 1000,
              }).then((res) => {
                setDetail({ characterList: res.list });
                setCharacterModalVisible(true);
              });
            }}
          >
            关联人物
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            danger
            onClick={() => {
              handleDelete(record.id);
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const { tableRender, getList, pagination, dataList } = useTable(
    { columns, rowKey: "id" },
    getMediaList
  );

  async function handleUpdate(data) {
    if (confirmLoading) return;
    data.disable = Number(!data.disable);
    data.release_date = moment(data.release_date).format("X");
    const params = formatParams(currentRow, data);
    console.log(params);
    if (params) {
      if (currentRow && currentRow.id) {
        await updateMedia(currentRow.id, params);
      } else {
        await createMedia(params);
      }
      await getList();
    }
    setUpdateModalVisible(false);
    setConfirmLoading(false);
  }

  function handleDelete(id) {
    console.log(id);
    confirm({
      title: "是否确认删除该媒体",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      // content: 'Some descriptions',
      onOk() {
        console.log("OK", id);
        return deleteMedia(id).then(() => {
          getList();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function handleSearch(searchParams) {
    if (searchParams.time) {
      searchParams.start_time = Math.floor(
        moment(searchParams.time[0]).valueOf() / 1000
      );
      searchParams.end_time = Math.ceil(
        moment(searchParams.time[1]).valueOf() / 1000
      );
      delete searchParams.time;
    }
    getList(searchParams);
  }

  async function handleExport() {
    const start_time = dataList[dataList.length - 1].create_time - 1;
    const end_time = dataList[0].create_time + 1;
    let res = await exportMediaData({
      start_time,
      end_time,
      page: pagination.current,
      perpage: pagination.pageSize,
    });
    const url = window.URL.createObjectURL(
      new Blob([res], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    console.log("表格数据", res);
    const fileName = "媒体列表" + new Date().getTime();
    link.style.display = "none";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setTimePickerVisible(false);
    setConfirmLoading(false);
    document.body.removeChild(link);
  }

  async function handleCharacterUpdate(data) {
    if (confirmLoading) return;
    const params = {
      id: data.map((item) => item.character_id).join(","),
    };
    console.log(params);
    await updateMediaCharacter(currentRow.id, params);
    setCharacterModalVisible(false);
    setConfirmLoading(false);
  }

  const uploadOptions = {
    name: "medias",
    action: "/api/v1/media/import",
    accept: ".xlsx,.xls",
    beforeUpload: (file) => {
      console.log("文件", file);
      if (file.size / 1024 / 1024 > 4) {
        message.error("文件大小不能超过4M！");
      }
      return false;
    },
    cb: () => {
      setImportVisible(false);
      getList();
    },
  };

  async function handleCrawl() {
    baidufrom
      .validateFields()
      .then((values) => {
        if (confirmLoading) return;
        console.log(values);
        let data = { ...values };
        data.from = values.url;
        console.log("crwal", data);
        setConfirmLoading(true);
        crawlBaiduData(data)
          .then(() => {
            setConfirmLoading(false);
            baidufrom.resetFields();
            setBaiduVisible(false);
          })
          .catch(() => {
            message.error("导入失败");
            setConfirmLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  async function getChannelData() {
    getChannelList({ page: 1, perPage: 1000 }).then((res) => {
      console.log(res);
      setChannelData(res.list);
    });
  }

  return (
    <div className="page">
      <PageHeader
        ghost={false}
        title="媒体列表"
        extra={[
          <Button
            type="primary"
            size="medium"
            key="baidu"
            onClick={() => {
              setBaiduVisible(true);
            }}
          >
            导入网页媒体分集数据
          </Button>,
          <Button
            type="primary"
            size="medium"
            key="new"
            onClick={() => {
              setCurrentRow(undefined);
              setUpdateModalVisible(true);
            }}
          >
            新建
          </Button>,
          <Button
            type="primary"
            size="medium"
            key="export"
            onClick={() => {
              handleExport();
            }}
          >
            导出
          </Button>,
          <Button
            type="primary"
            size="medium"
            key="import"
            onClick={() => {
              setImportVisible(true);
            }}
          >
            导入
          </Button>,
        ]}
      >
        <Form
          name="basic"
          autoComplete="off"
          form={form}
          onFinish={handleSearch}
        >
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item
                label="媒体ID"
                name="id"
                rules={[{ message: "Please input ID!" }]}
              >
                <Input placeholder="ID" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="名称" name="name">
                <Input placeholder="名称" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="状态" name="disable">
                <Select>
                  <Option value="0">可用</Option>
                  <Option value="1">不可用</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="time" label="媒体的创建时间范围">
                <RangePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="频道" name="channel_id">
                <Select>
                  {/* <Option value="0">可用</Option>
                  <Option value="1">不可用</Option> */}
                  {channelTagData &&
                    channelTagData.map((item, index) => {
                      return (
                        <Option key={"opt" + index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="应用类型" name="party">
                <Select>
                  {partyList &&
                    partyList.map((item, index) => {
                      return (
                        <Option key={"opt" + index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              {/* 没有来源接口，暂时只有百度百科数据。先写死 */}
              <Form.Item label="来源" name="source">
                <Select>
                  <Option value="1">百度百科</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="排序数值" name="order_num">
                <Input type='number' placeholder="排序数值" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="排序方式" name="sort">
                <Select>
                  <Option value='order_num.asc'>序号升序</Option>
                  <Option value='order_num.desc'>序号降序</Option>
                  <Option value='update_time.asc'>更新时间升序</Option>
                  <Option value='update_time.desc'>更新时间降序</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => {
                  form.resetFields();
                }}
              >
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </PageHeader>

      {tableRender()}
      {updateModalVisible && (
        <UpdateForm
          onSubmit={async (value) => {
            handleUpdate(value);
          }}
          onCancel={() => {
            setUpdateModalVisible(false);

            if (!showDetail) {
              setCurrentRow(undefined);
            }
          }}
          visible={updateModalVisible}
          confirmLoading={confirmLoading}
          data={currentRow}
          channelData={channelData}
        />
      )}
      {characterModalVisible && (
        <CharacterTransfer
          onSubmit={async (value) => {
            console.log("lsit value", value);
            handleCharacterUpdate(value);
          }}
          onCancel={() => {
            setCharacterModalVisible(false);
            setCurrentRow(undefined);
          }}
          confirmLoading={confirmLoading}
          visible={characterModalVisible}
          dataList={detail && detail.characterList}
        />
      )}
      {showDetail && (
        <Drawer
          title="媒体详情"
          width={1200}
          visible={showDetail}
          onClose={() => {
            setCurrentRow(undefined);
            setDetail(undefined);
            setShowDetail(false);
          }}
        >
          <Descriptions
            // title={`媒体详情 - ${detail.name}`}
            column={2}
            bordered
            labelStyle={{ width: "150px" }}
            contentStyle={{ width: "425px" }}
          >
            <Descriptions.Item label="ID">{detail.id}</Descriptions.Item>
            <Descriptions.Item label="题目ID">
              {detail.question_id}
            </Descriptions.Item>
            <Descriptions.Item label="名称">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="一句话简介" span={2}>
              {detail.aword}
            </Descriptions.Item>
            <Descriptions.Item label="海报">
              <Image width={50} src={detail.poster} />
            </Descriptions.Item>
            <Descriptions.Item label="剧照">
              <Image width={50} src={detail.still} />
            </Descriptions.Item>
            <Descriptions.Item label="剧情描述" span={2}>
              {detail.description}
            </Descriptions.Item>
            <Descriptions.Item label="导演">
              {detail.directors}
            </Descriptions.Item>
            <Descriptions.Item label="演员">{detail.actors}</Descriptions.Item>
            <Descriptions.Item label="评分">{detail.score}</Descriptions.Item>
            <Descriptions.Item label="状态">
              {tagRender(detail.disable)}
            </Descriptions.Item>
            <Descriptions.Item label="上映日期" span={2}>
              {moment(detail.release_date * 1000).format("YYYY-MM-DD HH:mm:ss")}
            </Descriptions.Item>
            <Descriptions.Item label="关联人物" span={2}>
              {detail.characterList.map((item, index) => (
                <Tag key={index}>{item.name}</Tag>
              ))}
            </Descriptions.Item>
          </Descriptions>
          <EpisodeList mediaId={detail.id} />
        </Drawer>
      )}
      {importVisible && (
        <Modal
          title="导入规则说明"
          visible={importVisible}
          onCancel={() => {
            setImportVisible(false);
          }}
          footer={null}
        >
          <p>
            注意：excel 文件包含表头，必须严格按 name, aword,
            description,directors,actors,score, release_date 顺序。 其中
            release_date 在excel中需要设置成文本格式,填写格式只支持到年，
            媒体导入时，如数据库已存在相同名称的媒体，则导入的媒体会被过滤掉。
          </p>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                setImportVisible(false);
              }}
              style={{ marginRight: "20px" }}
            >
              取消
            </Button>
            {/* <Upload {...uploadOptions} key="import">
              <Button type="primary" icon={<UploadOutlined />}>
                开始导入
              </Button>
            </Upload> */}
            <FileUploader {...uploadOptions} />
          </div>
        </Modal>
      )}
      {baiduVisible && (
        <Modal
          title="导入网页媒体分集数据"
          visible={baiduVisible}
          onCancel={() => {
            setBaiduVisible(false);
          }}
          confirmLoading={confirmLoading}
          onOk={handleCrawl}
        >
          {/* <Form autoComplete="off">
            <Form.Item label="百度百科地址" name="url">
              <Input placeholder="请输入百度百科地址"></Input>
            </Form.Item>
          </Form> */}

          <Form form={baidufrom} layout="" autoComplete="off">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="频道"
                  name="channel_id"
                  rules={[{ required: true, message: "请选择频道" }]}
                >
                  <Select>
                    <Option value="1">电影</Option>
                    <Option value="2">电视剧</Option>
                    {/* <Option value="3">综艺</Option>
                    <Option value="4">话题揭秘</Option> */}
                    {/* {channelData.length &&
                      channelData.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })} */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="来源"
                  name="source"
                  rules={[{ required: true, message: "请选择来源" }]}
                >
                  <Select>
                    <Option value="1">百度百科</Option>
                    <Option value="2">豆瓣</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="url"
                  label="网页地址："
                  rules={[
                    { required: true },
                    { type: "url", warningOnly: true },
                    { type: "string", min: 6 },
                  ]}
                >
                  <Input placeholder="请输入网页地址" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default MediaList;

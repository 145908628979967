import React, { useState } from 'react'
import { Modal, Form, Input, Switch, Button } from 'antd'
import FunUploader from '@components/FunUploader'
import TinyEditor from '@components/TinyEditor'
import styles from '../../../global.less'

const UpdateForm = (props) => {
  // console.log(props)
  const { data, onSubmit, onCancel, visible, confirmLoading } = props
  const [tinyEditorVisible, setTinyEditorVisible] = useState(false)
  const [tempDesc, setTempDesc] = useState(data?.description)

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? '编辑分集' : '新建分集'
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        is_lock: Boolean(data.is_lock),
      }
    : { is_lock: 0 }

  const [form] = Form.useForm()

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        onSubmit(values)
        // form.resetFields()
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  function handleDescription(content) {
    setTempDesc(content)
    form.setFieldsValue({
      description: content,
    })
    setTinyEditorVisible(false)
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: '32px 40px 48px',
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item name="name" label="名称">
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item
          name="order_num"
          label="分集序号"
          rules={[{ required: true, message: '请输入分集序号！' }]}
        >
          <Input placeholder="请输入分集序号" />
        </Form.Item>
        <Form.Item name="aword" label="一句话简介">
          <Input placeholder="请输入一句话简介" />
        </Form.Item>
        <Form.Item label="海报">
          <Form.Item name="poster">
            <Input placeholder="请输入海报" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.poster}
            onUploaded={(url) => {
              console.log('onUploaded', url)
              form.setFieldsValue({
                poster: url,
              })
            }}
          />
        </Form.Item>
        <Form.Item label="剧照">
          <Form.Item name="still">
            <Input placeholder="请输入剧照" disabled />
          </Form.Item>
          <FunUploader
            defaultUrl={data && data.still}
            onUploaded={(url) => {
              form.setFieldsValue({
                still: url,
              })
            }}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="剧集描述"
          rules={[{ required: true, message: '请输入剧集描述！' }]}
        >
          <div dangerouslySetInnerHTML={{ __html: tempDesc }} className={styles['html-wrapper']} />
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setTinyEditorVisible(true)
            }}
          >
            编辑人物描述
          </Button>
        </Form.Item>
        <Form.Item name="is_lock" label="	是否上锁" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
      {tinyEditorVisible && (
        <TinyEditor
          onCancel={() => {
            setTinyEditorVisible(false)
          }}
          visible={tinyEditorVisible}
          data={tempDesc}
          handleDescription={(content) => {
            handleDescription(content)
          }}
        />
      )}
    </Modal>
  )
}

export default UpdateForm

import { Menu, Dropdown } from 'antd';
import styles from './RightContent.less'
import React, { useState } from 'react';
import { LogoutOutlined } from "@ant-design/icons";
import LogoutModal from "./components/LogoutModal";

const RightContent = () => {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const user= window.sessionStorage.getItem('USER')
  const onMenuClick = () => {
    setLogoutModalVisible(true)
  };

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined/>
        退出登录
      </Menu.Item>
    </Menu>
  );

  const changeLogoutModalVisible = (status) => {
    setLogoutModalVisible(status)
  }

  return (
    <div className={styles['right']}>
      <Dropdown className={styles['action']} overlay={menuHeaderDropdown}>
        <span>
          {<span>你好，<a href="#">{user}</a></span> }
        </span>
      </Dropdown>
      {logoutModalVisible ?
        <LogoutModal
          visible={logoutModalVisible}
          changeLogoutModalVisible={changeLogoutModalVisible}/> : null}
    </div>
  );
};
export default RightContent;

import  request  from '@utils/request'

export async function getMountList(data) {
    return request('api/v1/mount', {
      method: 'GET',
      data,
    })
}

export async function deleteMount(id) {
    return request(`api/v1/mount/${id}`, {
      method: 'DELETE',
    })
}


export async function updateMount(id, data) {
    return request(`api/v1/mount/${id}`, {
      method: 'PUT',
      data,
    })
  }

    export async function createMount(data) {
    return request('api/v1/mount', {
      method: 'POST',
      data,
    })
  }

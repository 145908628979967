import  request  from '@utils/request'

export async function getMediaList(data) {
  return request('api/v1/media', {
    method: 'GET',
    data,
  })
}

export async function getMedia(id) {
  return request(`api/v1/media/${id}`, {
    method: 'GET',
  })
}

export async function createMedia(data) {
  return request(`api/v1/media`, {
    method: 'POST',
    data,
  })
}

export async function updateMedia(id, data) {
  return request(`api/v1/media/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function deleteMedia(id) {
  return request(`api/v1/media/${id}`, {
    method: 'DELETE',
  })
}

export async function getMediaCharacter(data) {
  return request(`api/v1/media/character`, {
    method: 'GET',
    data,
  })
}

export async function updateMediaCharacter(id, data) {
  return request(`api/v1/media/character/${id}`, {
    method: 'POST',
    data,
  })
}

//导出数据
export async function exportMediaData(data) {
  return request(`api/v1/media/export`, {
    method: 'GET',
    data,
    responseType: 'blob',
  })
}

//抓去百度百科数据
export async function crawlBaiduData(data) {
  return request('api/v1/crawl/media', {
    method: 'GET',
    data,
  })
}

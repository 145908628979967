import React, { useState, useCallback } from 'react'
import { Modal, message } from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import { uploadImage } from '@services/common'

const TinyEditor = (props) => {
  const { visible, handleDescription, data, onCancel } = props

  const [editorContent, setEditorContent] = useState()

  function handleEditorChange(content, editor) {
    setEditorContent(content)
  }

  function handleImageUpload(blobInfo, success, failure) {
    const formData = new FormData()
    formData.append('image_file', blobInfo.blob())
    uploadImage(formData)
      .then((res) => {
        console.log(res)
        const url = `http://img.funtv.bestv.com.cn/sdw?oid=${res.oid}&w=0&h=0`
        success(url)
      })
      .catch(() => {
        failure()
      })
  }

  function onOk() {
    if (editorContent.length > 20000) {
      return message.warning('超出最大字符限制')
    }
    handleDescription(editorContent)
  }

  return (
    <Modal
      width={1200}
      bodyStyle={{
        padding: '32px 40px 48px',
      }}
      destroyOnClose
      title="编辑描述"
      // confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
    >
      <Editor
        inline={false}
        initialValue={data}
        init={{
          height: 600,
          // width: 1000,
          language: 'zh_CN',
          plugins:
            'link image code powerpaste table textcolor lists link fullscreen wordcount fullpage',
          // menubar: "table",
          toolbar: [
            'newdocument | undo redo | styleselect formatselect fontselect fontsizeselect removeformat | cut copy paste | code | fullscreen',
            'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | forecolor backcolor | link | outdent indent | table ',
          ],
          paste_data_images: true,
          powerpaste_word_import: 'propmt', // 参数可以是propmt, merge, clear，效果自行切换对比
          powerpaste_html_import: 'propmt', // propmt, merge, clear
          powerpaste_allow_local_images: true,
          paste_data_images: true,
          images_upload_handler: (blobInfo, success, failure) => {
            handleImageUpload(blobInfo, success, failure)
          },
          fullpage_default_font_size: '19px',
          branding: false, // 移除右下角tinymce链接
          // setup: function (ed) {
          //   // 设置默认字号
          //   ed.on('init', function () {
          //     ed.getBody().style.fontSize = '24px'
          //   })
          // },
        }}
        onEditorChange={handleEditorChange}
      />
    </Modal>
  )
}

export default TinyEditor

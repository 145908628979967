import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'

// axios 封装
// 请求时的拦截
axios.interceptors.request.use(
  config => {
    // 发送请求之前做一些处理,loading...
    // Vue.prototype.$loading({
    //   target: '#main-view',
    //   background: 'rgba(0, 0, 0, 0.6)'
    // })
    return config
  },
  error => {
    // 当请求异常时做一些处理
    return Promise.reject(error)
  }
)

// 响应时拦截
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // console.log('interceptors response error', error)
    return Promise.reject(error)
  }
)

function errorState(error) {
  // console.log(error.response)
  if(error.response.data){
    message.error(error.response.data.msg)
  }
  if(error.response.status === 401){
    window.sessionStorage.setItem('TOKEN','')
    if(window.location.hash.indexOf('?redirect=') === -1){
      window.location.hash = '#/login?redirect=' + window.location.hash
    }
    console.log(window.location.hash )
  }
}

function successState(response) {
  // 统一判断后端返回的错误码
  // console.log('successState', response)
  if (response.errorCode&&response.errorCode != 0) {
      message.error(response.msg)
    return
  }
}


const request = (url, options) => {
  console.log('params', options)
  let Public = {}
  let qsOpts = { allowDots: true }
  if (options.qsOption) {
    qsOpts = Object.assign({ allowDots: true }, options.qsOption)
  }

  let params = options.data
  // console.log('data', data, qs.parse(data))

  let httpDefaultOpts = {
    // http默认配置
    method: options.method,
    // withCredentials: true, // 跨域获取cookie
    baseURL: process.env.BASE_URL,
    url: url,
    timeout: 30000,
    // params: Object.assign(Public, params),
    params: params,
    // paramsSerializer: function(params) {
    //   return qs.stringify(params, { arrayFormat: 'repeat' })
    // },
    data: params,
    // data: data instanceof FormData ? data : qs.stringify(Object.assign(Public, data), qsOpts),
    // data: qs.stringify(Object.assign(Public, data)),
    // headers:
    //   options.method.toUpperCase() === 'GET'
    //     ? {
    //         'X-Requested-With': 'XMLHttpRequest',
    //         'Content-Type': 'application/json; charset=UTF-8'
    //       }
    //     : {
    //         'X-Requested-With': 'XMLHttpRequest',
    //         'Content-Type': 'application/json; charset=UTF-8'
    //       }
  }
  const token = window.sessionStorage.getItem('TOKEN')

    httpDefaultOpts.headers = {
      ...(options?.headers ?? {}),
    }

    if (token) {
        httpDefaultOpts.headers.token = token
    }

  if (!options.method || options.method.toUpperCase() === 'GET') {
    delete httpDefaultOpts.data
  } else {
    delete httpDefaultOpts.params
    delete httpDefaultOpts.paramsSerializer
  }
  if (options.responseType) {
    httpDefaultOpts.responseType = options.responseType
  }
  if (options.headers) {
    httpDefaultOpts.headers['Content-Type'] = options.headers['Content-Type']
  }

  console.log('httpDefaultOpts',httpDefaultOpts)

  let promise = new Promise(function(resolve, reject) {
    axios(httpDefaultOpts)
      .then(response => {
        // console.log('success', response)
        console.log('response.data',response.data)
        if(httpDefaultOpts.responseType ==='blob'){
            resolve(response.data)
        }else{
          successState(response.data)
          if(response.data.errorCode === 0){
            resolve(response.data.data)
          }else{
            resolve(response.data)
          }
        }
      })
      .catch(response => {
        // console.log('error')
        if (!options.customError) {
          errorState(response)
        }
        reject(response)
      })
  })

  return promise
}

export default request

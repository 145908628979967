import React, { useState, useCallback } from 'react'
import { Upload, Modal } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { uploadImage } from '@services/common'

const FunUploader = (props) => {
  const { defaultUrl, onUploaded, options } = props

  const [fileList, setFileList] = useState(defaultUrl ? [{ url: defaultUrl }] : [])
  const [visible, setVisible] = useState(false)

  const onUpload = (options) => {
    console.log(options)
    const { onSuccess, onError, file, onProgress } = options
    const formData = new FormData()
    formData.append('image_file', file)
    console.log(formData, formData.get('image_file'))
    uploadImage(formData)
      .then((res) => {
        console.log(res)
        const url = `http://img.funtv.bestv.com.cn/sdw?oid=${res.oid}&w=0&h=0`
        onSuccess()
        onUploaded(url)
        setFileList([
          {
            url: url,
          },
        ])
      })
      .catch(() => {
        onError()
      })
  }

  const loading = false

  return (
    <div>
      <Upload
        accept="image/gif, image/jpeg, image/jpg, image/png"
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        maxCount={1}
        // defaultFileList={defaultUrl ? [{ url: defaultUrl }] : []}
        fileList={fileList}
        customRequest={(options) => {
          onUpload(options)
        }}
        onPreview={() => {
          setVisible(true)
        }}
        onRemove={() => {
          setFileList([])
          onUploaded('')
          return true
        }}
        {...options}
        // showUploadList={false}
        // beforeUpload={beforeUpload}
        // onChange={this.handleChange}
      >
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <Modal
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <img style={{ width: '100%' }} src={fileList[0]?.url} />
      </Modal>
    </div>
  )
}

export default FunUploader

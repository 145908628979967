import React, { useState } from 'react'
import { Modal, Form, Input, Switch } from 'antd'

const UpdateForm = (props) => {
  // console.log(props)
  const { data, onSubmit, onCancel, visible, confirmLoading } = props

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? '编辑用户' : '新建用户'
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        password: '',
        passwordConfirm: '',
      }
    : {}

  const [form] = Form.useForm()

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        onSubmit(values)
        // form.resetFields()
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: '32px 40px 48px',
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item name="name" label="名称" rules={[{ required: true, message: '请输入名称！' }]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item
          name="email"
          label="邮箱"
          rules={[
            { required: true, message: '请输入邮箱！' },
            { type: 'email', message: '请输入正确的邮箱！' },
          ]}
        >
          <Input placeholder="请输入邮箱" />
        </Form.Item>
        <Form.Item
          name="password"
          label="密码"
          // rules={[{ required: true, message: '请输入密码！' }]}
        >
          <Input.Password placeholder="请输入密码" />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label="确认密码"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('两次输入的密码不一致!'))
              },
            }),
          ]}
        >
          <Input.Password placeholder="请再次输入密码" />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateForm

import React, { useState } from 'react'
import { Modal, Form, Input, Switch, message, Select } from 'antd'
import moment from 'moment'

const UpdateForm = (props) => {
  // console.log(props)
  const { data, onSubmit, onCancel, visible, confirmLoading, channelList } = props

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? '编辑频道' : '新建频道'
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        release_date: moment(data.release_date * 1000),
      }
    : {}

  const [form] = Form.useForm()
  const {Option} = Select

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        onSubmit(values)
        form.resetFields()
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: '32px 40px 48px',
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item name="pid" label="父级频道">
          <Select>
            <Option key={'init'} value={0} >--</Option>
            {channelList.length &&
              channelList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })
            }
          </Select>
        </Form.Item>
        <Form.Item name="name" label="名称" rules={[{ required: true, message: '请输入名称！' }]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateForm

import React, { useState, useCallback } from "react";
import { Tag } from "antd";

const useTag = (data) => {
  if (!data) {
    data = [
      {
        key: 0,
        text: "可用",
        color: "green",
      },
      {
        key: 1,
        text: "不可用",
        color: "red",
      },
    ];
  }

  const [options, setOptions] = useState(data);
  const tagRender = (key) => {
    const { text, color } = options.find((item) => {
      return item.key === key;
    }) || { text: "", color: "" };
    return <Tag color={color}>{text}</Tag>;
  };
  return { tagRender };
};

export default useTag;

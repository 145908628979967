import  request  from '@utils/request'

export async function getEpisodeList(data) {
  return request('api/v1/episode', {
    method: 'GET',
    data,
  })
}

export async function getEpisode(id) {
  return request(`api/v1/episode/${id}`, {
    method: 'GET',
  })
}

export async function createEpisode(data) {
  return request(`api/v1/episode`, {
    method: 'POST',
    data,
  })
}

export async function updateEpisode(id, data) {
  return request(`api/v1/episode/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function deleteEpisode(id) {
  return request(`api/v1/episode/${id}`, {
    method: 'DELETE',
  })
}

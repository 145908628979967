import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  PageHeader,
  Button,
  Modal,
  Drawer,
  Divider,
  Descriptions,
  Image,
  Input,
  Form,
  Row,
  Col,
  Select,
  message
} from 'antd'
import React, { useState, useRef } from 'react'
import UpdateForm from './components/UpdateForm'
import FileUploader from '@/components/FileUploader'
import useTag from '@/hooks/useTag'
import useTable from '@/hooks/useTable'
import { formatParams } from '@/utils/util'
import moment from 'moment'
import {
  getEpisodeList,
  getEpisode,
  createEpisode,
  updateEpisode,
  deleteEpisode,
} from '@/services/episode'
import styles from '../../global.less'

const { confirm } = Modal

const EpisodeList = (props) => {
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [detail, setDetail] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  const [detailLoading, setDetailLoading] = useState({})
  const [importVisible, setImportVisible] = useState(false)

  const { tagRender } = useTag()
  const [form] = Form.useForm()
  const { Option } = Select
  const { tagRender: lockRender } = useTag([
    {
      key: 0,
      text: '否',
      color: 'red',
    },
    {
      key: 1,
      text: '是',
      color: 'green',
    },
  ])

  const columns = [
    {
      title: '分集ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 80,
    },
    {
      title: '名称',
      dataIndex: 'name',
      fixed: 'left',
      width: 200,
    },
    {
      title: '媒体ID',
      dataIndex: 'media_id',
      width: 100,
    },
    {
      title: '分集序号',
      dataIndex: 'order_num',
      width: 100,
    },
    {
      title: '一句话简介',
      dataIndex: 'aword',
      width: 200,
    },
    {
      title: '海报',
      dataIndex: 'poster',
      width: 100,
      render: (text) => {
        return <Image width={50} src={text} />
      },
    },
    {
      title: '剧照',
      dataIndex: 'still',
      render: (text) => {
        return <Image width={50} src={text} />
      },
    },
    // {
    //   title: '剧情描述',
    //   dataIndex: 'description',
    //   width: 300,
    // },
    {
      title: '是否上锁',
      dataIndex: 'is_lock',
      width: 100,
      render: (text) => {
        return lockRender(text)
      },
    },
    {
      title: '状态',
      dataIndex: 'disable',
      width: 100,
      render: (text) => {
        return tagRender(text)
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      width: 200,
      render: (text) => {
        return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '操作',
      dataIndex: 'option',
      fixed: 'right',
      width: 200,
      valueType: 'option',
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            loading={detailLoading[`data${record.id}`]}
            disabled={Object.keys(detailLoading).some((key) => detailLoading[key])}
            onClick={() => {
              console.log(record.id, detailLoading[record.id])
              setDetailLoading((detailLoading) => {
                const result = {
                  ...detailLoading,
                }
                result[`data${record.id}`] = true
                return result
              })
              setCurrentRow(record)
              getEpisode(record.id).then((res) => {
                setDetail(res)
                setShowDetail(true)
                setDetailLoading((detailLoading) => {
                  const result = {
                    ...detailLoading,
                  }
                  result[`data${record.id}`] = false
                  return result
                })
              })
            }}
          >
            详情
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record)
              setUpdateModalVisible(true)
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            danger
            onClick={() => {
              handleDelete(record.id)
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]

  let listParams = {}
  if (props.mediaId) listParams.media_id = props.mediaId

  const { tableRender, getList } = useTable(
    { columns, rowKey: 'id', ...props.tableOptions },
    getEpisodeList,
    listParams,
  )
  function handleSearch(searchParams) {
    getList(searchParams)
  }

  async function handleUpdate(data) {
    if (confirmLoading) return
    data.media_id = listParams.media_id
    data.disable = Number(!data.disable)
    data.is_lock = Number(data.is_lock)
    const params = formatParams(currentRow, data)
    console.log(params)
    if (params) {
      if (currentRow && currentRow.id) {
        await updateEpisode(currentRow.id, params)
      } else {
        await createEpisode(params)
      }
      if (listParams.media_id) {
        await getList({ media_id: listParams.media_id })
      } else {
        await getList()
      }
    }
    setUpdateModalVisible(false)
    setConfirmLoading(false)
  }

  function handleDelete(id) {
    console.log(id)
    confirm({
      title: '是否确认删除该用户',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      // content: 'Some descriptions',
      onOk() {
        console.log('OK', id)
        return deleteEpisode(id).then(() => {
          getList()
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  const uploadOptions = {
    name: 'episodes',
    action: '/api/v1/episode/import',
    accept: '.xlsx,.xls',
    beforeUpload: (file) => {
      console.log('文件', file)
      if (file.size / 1024 / 1024 > 4) {
        message.error('文件大小不能超过4M！')
      }
      return false
    },
    cb: () => {
      getList()
      setImportVisible(false)
    },
  }

  return (
    <div className="page">
      {!props.hideHeader && (
        <PageHeader
          ghost={false}
          title="分集列表"
          extra={[
            props.mediaId && (
              <Button
                type="primary"
                size="medium"
                key="new"
                onClick={() => {
                  setCurrentRow(undefined)
                  setUpdateModalVisible(true)
                }}
              >
                新建
              </Button>
            ),
            <Button
              type="primary"
              size="medium"
              key="import"
              onClick={() => {
                setImportVisible(true)
              }}
            >
              导入
            </Button>,
          ]}
        >
          <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="分集ID" name="id" rules={[{ message: 'Please input ID!' }]}>
                  <Input placeholder="ID" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="名称" name="name" rules={[{ message: 'Please input name!' }]}>
                  <Input placeholder="名称" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="媒体ID"
                  name="media_id"
                  rules={[{ message: 'Please input media ID!' }]}
                >
                  <Input placeholder="媒体ID" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="是否上锁"
                  name="is_lock"
                  rules={[{ message: 'Please input ID!' }]}
                >
                  <Select>
                    <Option value="0">否</Option>
                    <Option value="1">是</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="状态" name="disable">
                  <Select>
                    <Option value="0">可用</Option>
                    <Option value="1">不可用</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序方式" name="sort">
                  <Select>
                    <Option value='order_num.asc'>序号升序</Option>
                    <Option value='order_num.desc'>序号降序</Option>
                    <Option value='update_time.asc'>更新时间升序</Option>
                    <Option value='update_time.desc'>更新时间降序</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
      {tableRender()}
      {updateModalVisible && (
        <UpdateForm
          onSubmit={async (value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setUpdateModalVisible(false)

            if (!showDetail) {
              setCurrentRow(undefined)
            }
          }}
          visible={updateModalVisible}
          confirmLoading={confirmLoading}
          data={currentRow}
        />
      )}
      {showDetail && (
        <Drawer
          width={600}
          visible={showDetail}
          onClose={() => {
            setCurrentRow(undefined)
            setDetail(null)
            setShowDetail(false)
          }}
          closable={false}
        >
          {detail && (
            <Descriptions title={detail.name} column={1} bordered labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="ID">{detail.id}</Descriptions.Item>
              <Descriptions.Item label="名称">{detail.name}</Descriptions.Item>
              <Descriptions.Item label="一句话简介">{detail.aword}</Descriptions.Item>
              <Descriptions.Item label="海报">
                <Image width={50} src={detail.poster} />
              </Descriptions.Item>
              <Descriptions.Item label="剧照">
                <Image width={50} src={detail.still} />
              </Descriptions.Item>
              <Descriptions.Item label="剧情描述">
                <div
                  dangerouslySetInnerHTML={{ __html: detail.description }}
                  className={styles['html-wrapper']}
                />
              </Descriptions.Item>
              <Descriptions.Item label="导演">{detail.directors}</Descriptions.Item>
              <Descriptions.Item label="演员">{detail.actors}</Descriptions.Item>
              <Descriptions.Item label="评分">{detail.score}</Descriptions.Item>
              <Descriptions.Item label="状态">{tagRender(detail.disable)}</Descriptions.Item>
              <Descriptions.Item label="更新时间">
                {moment(detail.update_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Drawer>
      )}
      {importVisible && (
        <Modal
          title="导入规则说明"
          visible={importVisible}
          onCancel={() => {
            setImportVisible(false)
          }}
          footer={null}
        >
          <p>
            注意：excel 文件包含表头，必须严格按 name, aword, description,order_num,media_id
            顺序。其中 birth_death 在excel中需要设置成文本格式 分集导入时，不进行名称重复的过滤。
          </p>
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                setImportVisible(false)
              }}
              style={{ marginRight: '20px' }}
            >
              取消
            </Button>
            {/* <Upload {...uploadOptions} key="import">
              <Button type="primary" icon={<UploadOutlined />}>
                开始导入
              </Button>
            </Upload> */}
            <FileUploader {...uploadOptions} />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default EpisodeList

import  request  from '@utils/request'

export async function getTabList(data) {
    return request('api/v1/tab', {
      method: 'GET',
      data,
    })
}

export async function deleteTab(id) {
    return request(`api/v1/tab/${id}`, {
      method: 'DELETE',
    })
}


export async function updateTab(id, data) {
    return request(`api/v1/tab/${id}`, {
      method: 'PUT',
      data,
    })
  }

    export async function createTab(data) {
    return request('api/v1/tab', {
      method: 'POST',
      data,
    })
  }

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PageHeader, Button, Modal, Drawer, Divider, Descriptions } from 'antd'
import React, { useState, useRef } from 'react'
import UpdateForm from './components/UpdateForm'
import useTag from '@/hooks/useTag'
import useTable from '@/hooks/useTable'
import { formatParams } from '@/utils/util'
import moment from 'moment'
import { getUserList, getUser, createUser, updateUser, deleteUser } from '@/services/user'

const { confirm } = Modal

const TableList = () => {
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [detail, setDetail] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  const [detailLoading, setDetailLoading] = useState({})

  const { tagRender } = useTag()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
    },
    {
      title: '状态',
      dataIndex: 'disable',
      render: (text) => {
        return tagRender(text)
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      render: (text) => {
        return moment(text * 1000).format('YYYY-MM-DD, HH:mm:ss')
      },
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'option',
      valueType: 'option',
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            loading={detailLoading[`data${record.id}`]}
            disabled={Object.keys(detailLoading).some((key) => detailLoading[key])}
            onClick={() => {
              console.log(record.id, detailLoading[record.id])
              setDetailLoading((detailLoading) => {
                const result = {
                  ...detailLoading,
                }
                result[`data${record.id}`] = true
                return result
              })
              setCurrentRow(record)
              getUser(record.id).then((res) => {
                setDetail(res)
                setShowDetail(true)
                setDetailLoading((detailLoading) => {
                  const result = {
                    ...detailLoading,
                  }
                  result[`data${record.id}`] = false
                  return result
                })
              })
            }}
          >
            详情
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record)
              setUpdateModalVisible(true)
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            danger
            onClick={() => {
              handleDelete(record.id)
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]

  const { tableRender, getList } = useTable({ columns, rowKey: 'id' }, getUserList)

  async function handleUpdate(data) {
    try {
      if (confirmLoading) return
      data.disable = Number(!data.disable)
      delete data.passwordConfirm
      if (!data.password) {
        delete data.password
      }
      const params = formatParams(currentRow, data)
      console.log(params)
      if (params) {
        if (currentRow && currentRow.id) {
          await updateUser(currentRow.id, params)
        } else {
          await createUser(params)
        }
        await getList()
      }
      setUpdateModalVisible(false)
      setConfirmLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  function handleDelete(id) {
    console.log(id)
    confirm({
      title: '是否确认删除该用户',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      // content: 'Some descriptions',
      onOk() {
        console.log('OK', id)
        return deleteUser(id).then(() => {
          getList()
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return (
    <div className="page">
      <PageHeader
        ghost={false}
        title="用户列表"
        extra={[
          <Button
            type="primary"
            size="medium"
            key="new"
            onClick={() => {
              setCurrentRow(undefined)
              setUpdateModalVisible(true)
            }}
          >
            新建
          </Button>,
        ]}
      ></PageHeader>
      {tableRender()}
      {updateModalVisible && (
        <UpdateForm
          onSubmit={async (value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setUpdateModalVisible(false)

            if (!showDetail) {
              setCurrentRow(undefined)
            }
          }}
          visible={updateModalVisible}
          confirmLoading={confirmLoading}
          data={currentRow}
        />
      )}
      {showDetail && (
        <Drawer
          width={400}
          visible={showDetail}
          onClose={() => {
            setCurrentRow(undefined)
            setDetail(null)
            setShowDetail(false)
          }}
          closable={false}
        >
          {detail && (
            <Descriptions title={detail.name} column={1} bordered labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="ID">{detail.id}</Descriptions.Item>
              <Descriptions.Item label="名称">{detail.name}</Descriptions.Item>
              <Descriptions.Item label="邮箱">{detail.email}</Descriptions.Item>
              <Descriptions.Item label="状态">{tagRender(detail.disable)}</Descriptions.Item>
              <Descriptions.Item label="更新时间">
                {moment(detail.update_time * 1000).format('YYYY-MM-DD, HH:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Drawer>
      )}
    </div>
  )
}

export default TableList

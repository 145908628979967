import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    PageHeader,
    Button,
    Modal,
    Row,
    Col,
    Input,
    Form,
    Image,
    Divider,
    Select,
    DatePicker,
  } from 'antd'
  import React, { useEffect, useState } from 'react'
  import useTag from '@/hooks/useTag'
  import useTableData from '@/hooks/useTableData'
  import BasicTable from '@/components/BasicTable'
  import UpdateForm from "./components/UpdateForm";
  import { formatParams } from '@/utils/util'
  import moment from 'moment'
  import {
    getAppList ,
    deleteApp,
    updateApp,
    createApp
  } from '@/services/app'
  import commonData from '@/common/commonData'

  const AppList = () => {
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [currentRow, setCurrentRow] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [partyList, setPartyList] = useState([])

    const { tagRender } = useTag()
    const [form] = Form.useForm()
    const { Option } = Select
    const { confirm } = Modal
    const { RangePicker } = DatePicker
    const {partyList} = commonData

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        fixed: 'left',
        width: 80,
      },
      {
        title: '名称',
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: '应用类型',
        dataIndex: 'party',
        render: (text, record) => {
            return partyList?.find(item => item.id === record.party)?.name
        }
      },
      {
        title: 'APP_ID',
        dataIndex: 'app_id',
        width: 100
      },
      {
        title: 'APP_KEY',
        dataIndex: 'app_key'
      },
      {
        title: '状态',
        dataIndex: 'disable',
        width: 100,
        render: (text) => {
          return tagRender(text)
        }
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        width: 200,
        render: (text) => {
          return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
      },
      {
        title: '更新时间',
        dataIndex: 'update_time',
        width: 200,
        render: (text) => {
          return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
      },
      // {
      //   title: '操作',
      //   dataIndex: 'option',
      //   fixed: 'right',
      //   width: 150,
      //   valueType: 'option',
      //   render: (_, record) => (
      //     <>
      //       <Button
      //         type="link"
      //         size="small"
      //         onClick={() => {
      //           setCurrentRow(record)
      //           setUpdateModalVisible(true)
      //         }}
      //       >
      //         编辑
      //       </Button>
      //       <Divider type="vertical" />
      //       <Button
      //         type="link"
      //         size="small"
      //         danger
      //         onClick={() => {
      //           handleDelete(record.id)
      //         }}
      //       >
      //         删除
      //       </Button>
      //     </>
      //   ),
      // },
    ]

    function handleDelete(id) {
        confirm({
          title: "是否确认删除该应用？",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          onOk() {
            return deleteApp(id).then(() => {
              getList();
            });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
    }

    const { pagination, dataList, handleChange, getList,tableLoading } = useTableData(getAppList, {})
    async function handleSearch(searchParams) {
      setLoading(true)
      if (searchParams.time) {
        searchParams.start_time = Math.floor(moment(searchParams.time[0]).valueOf() / 1000)
        searchParams.end_time = Math.ceil(moment(searchParams.time[1]).valueOf() / 1000)
        delete searchParams.time
      }
      await getList(searchParams)
      setLoading(false)
    }

    async function handleUpdate(data){
        data.disable = Number(!data.disable)
        const params = formatParams(currentRow, data)
        if (params) {
            if (currentRow && currentRow.id) {
                await updateApp(currentRow.id, params)
            } else {
                await createApp(params)
            }
            await getList()
        }
        setUpdateModalVisible(false)
        setConfirmLoading(false)
    }

    const tableRender = () => {
      return (
        <BasicTable
          columns={columns}
          pagination={pagination}
          handleChange={handleChange}
          dataList={dataList}
          loading={tableLoading}
        ></BasicTable>
      )
    }

    return (
      <div className="page">
        <PageHeader
            ghost={false}
            title="应用列表"
        >
          <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="应用ID" name="id" rules={[{ message: 'Please input ID!' }]}>
                  <Input placeholder="ID" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="名称" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="状态" name="disable">
                  <Select>
                    <Option value="0">可用</Option>
                    <Option value="1">不可用</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="time" label="媒体的创建时间范围">
                  <RangePicker showTime />
                </Form.Item>
              </Col> */}
              <Col span={4}>
                    <Form.Item label="应用类型" name="party">
                        <Select>
                            {partyList &&
                            partyList.map((item, index) => {
                            return (
                                <Option key={"opt" + index} value={item.id}>
                                {item.name}
                                </Option>
                            );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </PageHeader>

        {tableRender()}
        {updateModalVisible && (
            <UpdateForm
            onSubmit={async (value) => {
                handleUpdate(value)
            }}
            onCancel={() => {
                setUpdateModalVisible(false)
                setCurrentRow(undefined)
            }}
            visible={updateModalVisible}
            confirmLoading={confirmLoading}
            data={currentRow}
            partyList={partyList}
            />
        )}
      </div>
    )
  }

  export default AppList

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
    partyList,
    channelList
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? "编辑标签" : "新建标签";
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable),
        release_date: moment(data.release_date * 1000),
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;



  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        {data?.id&&<Form.Item name="id" label="标签id">
          <Input disabled={data?.id} placeholder="请输入标签ID！" type="number" />
        </Form.Item>}
        <Form.Item name="channel_id" label="频道"  rules={[{ required: true, message: "必须选择频道！" }]}>
          <Select>
            {channelList.length &&
              channelList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="名称"
          rules={[{ required: true, message: "请输入名称！" }]}
        >
          <Input placeholder="请输入名称" minLength={1} maxLength={30}/>
        </Form.Item>
        <Form.Item name="order_num" label="排序数值">
          <Input placeholder="请输入排序数值！" type="number" />
        </Form.Item>
        <Form.Item label="应用类型" name="party" rules={[{ required: true, message: "必须选择应用类型！" }]}>
          <Select>
            {partyList.length &&
              partyList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

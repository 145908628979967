import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    PageHeader,
    Button,
    Modal,
    Row,
    Col,
    Input,
    Form,
    Image,
    Divider,
    Select,
    DatePicker,
    message,
  } from 'antd'
  import React, { useEffect, useState } from 'react'
  import useTag from '@/hooks/useTag'
  import useTableData from '@/hooks/useTableData'
  import BasicTable from '@/components/BasicTable'
  import UpdateForm from "./components/UpdateForm";
  import { getChannelList } from '@/services/channel'
  import { formatParams } from '@/utils/util'
  import md5 from 'js-md5'
  import qs from 'qs';
  import moment from 'moment'
  import {
    getAttachList ,
    deleteAttach,
    updateAttach,
    createAttach
  } from '@/services/attach'
  import { getAppList } from '@/services/app'
  import {getSecretList} from '@/services/secret'
  import commonData from '@/common/commonData'

  const AttachList = () => {
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [currentRow, setCurrentRow] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [channelList, setChannelList] = useState([]);
    const [appList, setAppList] = useState([])
    const [secretList , setSecretList] = useState([]);

    const { tagRender } = useTag([
      {
        key: 0,
        text: "审核通过",
        color: "green",
      },
      {
        key: 1,
        text: "审核不通过",
        color: "red",
      },
      {
        key: 2,
        text: "审核中",
        color: "yellow",
      },
    ])
    const [form] = Form.useForm()
    const { Option } = Select
    const { confirm } = Modal
    const { RangePicker } = DatePicker
    const {partyList} = commonData

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        fixed: 'left',
        width: 80,
      },
      {
        title: '应用类型',
        dataIndex: 'app_id',
        fixed: 'left',
        render: (text, record) => {
          return appList?.find(item => item.app_id === record.app_id)?.name
        }
      },
      {
        title: '达人ID',
        dataIndex: 'talent_id',
        fixed: 'left',
      },
      {
        title: '达人作品ID',
        dataIndex: 'work_id',
      },
      {
        title:'挂载文案',
        dataIndex:'talent_doc',
        width:150
      },
      {
        title: '频道',
        dataIndex: 'channel_id',
        render: (text, record) => {
            return channelList?.find(item => item.id === record.channel_id)?.name
        }
      },
      {
        title: '实体对象ID',
        dataIndex: 'entity_id',
        width:100
      },
      {
        title: '排序序号',
        dataIndex: 'order_num',
        width:80
      },
      {
        title: '资源审核状态',
        dataIndex: 'disable',
        render: (text) => {
          return tagRender(text)
        }
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        width: 200,
        render: (text) => {
          if(text){
            return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
          }else{
            return '--'
          }
        },
      },
      {
        title: '更新时间',
        dataIndex: 'update_time',
        width: 200,
        render: (text) => {
          return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        fixed: 'right',
        valueType: 'option',
        render: (_, record) => (
          <>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setCurrentRow(record)
                setUpdateModalVisible(true)
              }}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              size="small"
              danger
              onClick={() => {
                handleDelete(record.id)
              }}
            >
              删除
            </Button>
          </>
        ),
      },
    ]

    function handleDelete(id) {
        confirm({
          title: "是否确认删除该资源？",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          onOk() {
            return deleteAttach(id).then(() => {
              getList();
            });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
    }

    const { pagination, dataList, handleChange, getList ,tableLoading} = useTableData(getAttachList, {})
    async function handleSearch(searchParams) {
      setLoading(true)
      if (searchParams.time) {
        searchParams.start_time = Math.floor(moment(searchParams.time[0]).valueOf() / 1000)
        searchParams.end_time = Math.ceil(moment(searchParams.time[1]).valueOf() / 1000)
        delete searchParams.time
      }
      await getList(searchParams)
      setLoading(false)
    }

    async function handleUpdate(data){
      console.log('sdasdasdadasdasdasdasd',data)
      //星星知道：
      // 所在端：kuaishou_miniapp_xxzd
      // AppID(小程序ID)：ks681732396126659077


      // 3.剧情社：
      // 所在端：kuaishou_miniapp_jqs
      // AppID(小程序ID)：ks711287269397471271

      // 快手_星星知道  1fds23ah74gf89d
      // 快手_剧情社  1g23fdhd7s8fd

      let business =''
      let secretkey =''
      let mount_path= ''
      let time = Date.now()
      //秒级时间戳
      time  = Math.floor(time / 1000)
      if(data.app_id === 'twink'){
        business ='kuaishou_miniapp_xxzd'
        secretkey ='1fds23ah74gf89d'
        mount_path=`/pages/episodePage/episodePage?mid=${data.entity_id}&eid=&ctime=${time}&source=fix&entrance=share${data.talent_id}`
      }else if(data.app_id === 'community'){
        business ='kuaishou_miniapp_jqs'
        secretkey ='1g23fdhd7s8fd'
        mount_path = `/pages/secretPage/secretPage?mid=${data.entity_id}&ctime=${time}&source=fix&entrance=share${data.talent_id}`
      }
      let audit_status = ''

      if(!business){
        message.error('该业务线暂不支持')
        return
      }
      switch (data.disable) {
        case 0:
          audit_status='audited'
          break;
          case 2:
          audit_status='toaudit'
            break;
            case 1:
            audit_status='rejected'
              break;
        default:
          audit_status='rejected'
          break;
      }
      console.log('sign',`str=${audit_status}${business}${mount_path}${data.talent_doc}${data.entity_id}${secretkey}${data.talent_id}${data.work_id}`)
      let mountData ={
        //业务线code
        business: business,
        user_id: data.talent_id,
        works_id: data.work_id,
        mount_txt: data.talent_doc,
        mount_path: mount_path,
        res_id: data.entity_id,
        audit_status,
        sign:md5(`${audit_status}${business}${mount_path}${data.talent_doc}${data.entity_id}${secretkey}${data.talent_id}${data.work_id}`),
      }
      mountData = qs.stringify(mountData)
        const params = formatParams(currentRow, data)
        if (params) {
            if (currentRow && currentRow.id) {
                params.version_id = data.version_id
                if(data.disable === 0){

                }else{
                  updateAttach(currentRow.id, params)
                }
            } else {
              if(data.disable === 0){

                }else{
                  createAttach(params)
                }
            }
            await getList()
        }
        setUpdateModalVisible(false)
        setConfirmLoading(false)
    }

    useEffect(()=>{
      getChannelList({ page: 1, perPage: 1000 }).then(res => {
          setChannelList(res.list)
        })
      getSecretList({ page: 1, perPage: 1000 }).then(res => {
        setSecretList(res.list)
      })
      getAppList({ page: 1, perPage: 1000 }).then(res => {
        setAppList(res.list)
      })
    },[])

    const tableRender = () => {
      return (
        <BasicTable
          columns={columns}
          pagination={pagination}
          handleChange={handleChange}
          dataList={dataList}
          loading={tableLoading}
        ></BasicTable>
      )
    }

    return (
      <div className="page">
        <PageHeader
            ghost={false}
            title="达人资源列表"
            extra={[
                <Button
                type="primary"
                size="medium"
                key="new"
                onClick={() => {
                    setCurrentRow(undefined)
                    setUpdateModalVisible(true)
                }}
                >
                新建
                </Button>,
            ]}
        >
          <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="ID" name="ids" rules={[{ message: 'Please input ID!' }]}>
                  <Input placeholder="多个请用英文逗号拼接" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="应用类型" name="app_id">
                  <Select>
                    {appList.length &&
                      appList.map((item) => {
                        return (
                          <Option key={item.id} value={item.app_id}>
                            {item.name}
                          </Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="time" label="达人资源的创建时间范围">
                  <RangePicker showTime />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="达人ID" name="talent_id">
                <Input placeholder="达人ID" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="实体名称" name="name">
                  <Input placeholder="名称" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="作品ID" name="work_id">
                  <Input placeholder="作品ID" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="频道ID" name="channel_id">
                  <Select>
                    {channelList.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="实体ID" name="entity_id">
                  <Input placeholder="作品ID" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="资源状态" name="disable">
                  <Select>
                    <Option value={0} >审核通过</Option>
                    <Option value={1}>审核不通过</Option>
                    <Option value={2}>审核中</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序数值" name="order_num">
                  <Input placeholder="数值" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序方式" name="sort">
                  <Select>
                    <Option value='order_num.asc'>序号升序</Option>
                    <Option value='order_num.desc'>序号降序</Option>
                    <Option value='update_time.asc'>更新时间升序</Option>
                    <Option value='update_time.desc'>更新时间降序</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </PageHeader>

        {tableRender()}
        {updateModalVisible && (
            <UpdateForm
            onSubmit={async (value) => {
                handleUpdate(value)
            }}
            onCancel={() => {
                setUpdateModalVisible(false)
                setCurrentRow(undefined)
            }}
            visible={updateModalVisible}
            confirmLoading={confirmLoading}
            data={currentRow}
            partyList={partyList}
            channelList={channelList}
            secretList={secretList}
            appList={appList}
            />
        )}
      </div>
    )
  }

  export default AttachList

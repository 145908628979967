import  request  from '@utils/request'

export async function uploadImage(data) {
  console.log(data)
  return request('uploadImage', {
    method: 'POST',
    data,
    requestType: 'form',
  })
}

//文件上传方法
export async function uploadFile(api, data) {
  return request(api, {
    method: 'POST',
    data,
    requestType: 'form',
  })
}

import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Table, message } from 'antd'
import useDebounce from '@/hooks/useDebounce'

const useTable = (options, api, defaultParams) => {
  const [dataList, setDataList] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    pageSizeOptions: [50, 100, 200],
    total: 0,
    showSizeChanger: true,
    showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条/总共 ${total} 条`,
  })
  const [scroll, setScroll] = useState({ x: 'max-content' })
  const [loading, setLoading] = useState(false)

  const wrapper = useRef(null)

  const getList = useDebounce(async (params) => {
    setLoading(true)
    const res = await api({
      page: pagination.current,
      perpage: pagination.pageSize,
      ...params,
    })
    setDataList(res.list)
    setLoading(false)
    setPagination({
      ...pagination,
      current: res.page,
      pageSize: res.perpage,
      total: res.total,
    })
  },300)

  useEffect(() => {
    setLoading(true)
    getList(defaultParams)
  }, [])

  useEffect(() => {
    const setTableHeight = () => {
      console.log(wrapper.current)
      if (!wrapper.current) return
      const tbody = wrapper.current.getElementsByClassName('ant-table-tbody')[0]
      const thead = wrapper.current.getElementsByClassName('ant-table-thead')[0]
      const pagination = wrapper.current.getElementsByClassName('ant-pagination')[0]

      let headerHeight = thead ? thead.offsetHeight : 0
      let paginationHeight = pagination ? pagination.offsetHeight + 35 : 0

      // console.log(boxer, headerHeight, paginationHeight)
      // let maxHeight = boxer- headerHeight - paginationHeight - 48
      // let minHeight = wrapper.current.offsetHeight - headerHeight - paginationHeight
      console.log('wrapper.current.offsetHeight',wrapper.current.offsetHeight)
      console.log('wrapper.current.offsetHeight - headerHeight - paginationHeight',wrapper.current.offsetHeight - headerHeight - paginationHeight)
      // console.log('maxHeight minHeight',maxHeight,minHeight)
      if (wrapper.current.offsetHeight - headerHeight - paginationHeight >= tbody.offsetHeight) {
        setScroll({ x: 'max-content' })
      } else {
        console.log(wrapper.current.offsetHeight - headerHeight - paginationHeight)
        setScroll({
          x: 'max-content',
          y: wrapper.current.offsetHeight - headerHeight - paginationHeight
        })
      }
    }

    setTableHeight()

    window.addEventListener('resize', setTableHeight)

    return () => {
      window.removeEventListener('resize', setTableHeight)
    }
  }, [dataList])

  const handleChange = (pageParams) => {
    let params = {
      ...defaultParams,
      page: pageParams.current,
      perpage: pageParams.pageSize,
    }
    getList(params)
  }

  const tableRender = () => {
    return (
      <div ref={wrapper} className="table-wrapper">
        <Table
          dataSource={dataList}
          rowKey="id"
          // columns={columns}
          scroll={scroll}
          // scrollToFirstRowOnChange
          // rowSelection={rowSelection}
          size="small"
          pagination={pagination}
          onChange={handleChange}
          bordered
          loading={loading}
          {...options}
        />
      </div>
    )
  }
  return { tableRender, getList, pagination, dataList }
}

export default useTable

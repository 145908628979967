import  request  from '@utils/request'

//应用类型列表
export async function getAppList(data) {
    return request('api/v1/app', {
      method: 'GET',
      data,
    })
}

export async function deleteApp(id) {
    return request(`api/v1/app/${id}`, {
      method: 'DELETE',
    })
}


export async function updateApp(id, data) {
    return request(`api/v1/app/${id}`, {
      method: 'PUT',
      data,
    })
  }

    export async function createApp(data) {
    return request('api/v1/app', {
      method: 'POST',
      data,
    })
  }

import React, { useState, useCallback } from 'react'
import { Upload, Modal, Button, message } from 'antd'
import { uploadFile } from '@services/common'
import { UploadOutlined } from '@ant-design/icons'

const FileUploader = (props) => {
  const { name, action, accept, options, textname, cb } = props
  const onUpload = (options) => {
    console.log(options)
    const { file } = options
    const formData = new FormData()
    formData.append(name, file)
    console.log(formData, formData.get(name))
    uploadFile(action, formData).then((res) => {
      console.log('!res.length', !res.length)
      if (!res.length) {
        message.success('导入成功')
      }
      if (cb) {
        cb()
      }
    })
  }
  return (
    <Upload
      customRequest={(options) => {
        onUpload(options)
      }}
      accept={accept}
      {...options}
      showUploadList={false}
    >
      <Button type="primary" icon={<UploadOutlined />}>
        {textname || '导入'}
      </Button>
    </Upload>
  )
}
export default FileUploader

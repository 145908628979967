import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
    // partyList
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? "编辑应用" : "新建应用";
  const initialValues = data?.id
    ? {
        ...data,
        disable: !Boolean(data.disable)
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;



  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        {data?.id&&<Form.Item name="id" label="应用id">
          <Input disabled={data?.id} placeholder="请输入应用ID！" type="number" />
        </Form.Item>}
        <Form.Item
          name="app_id"
          label="APPID"
          rules={[{ required: true, message: "请输入应用APPID！" }]}
        >
          <Input placeholder="请输入应用APPID" />
        </Form.Item>
        <Form.Item
          name="name"
          label="名称"
          rules={[{ required: true, message: "请输入名称！" }]}
        >
          <Input placeholder="请输入名称"  minLength={2}/>
        </Form.Item>
        {/* <Form.Item
          name="app_key"
          label="APP_KEY"
          rules={[{ required: true, message: "请输入32位应用密匙！", min: 32,max:32 }]}
        >
          <Input placeholder="请输入名称" maxLength={32} minLength={32}/>
        </Form.Item> */}
        <Form.Item label="应用类型" name="party" rules={[{ required: true, message: "必须选择应用类型！" }]}>
          <Select>
            {/* {partyList.length &&
              partyList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })} */}
              <Option key={'party1'} value={1}>管理后台</Option>
              {/* <Option key={'party2'} value={2}>用户界面</Option> */}
              <Option key={'party3'} value={3}>小程序</Option>
              <Option key={'party4'} value={4}>影视趣答</Option>
              <Option key={'party5'} value={5}>剧情社</Option>
              <Option key={'party6'} value={6}>星星知道</Option>
          </Select>
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
    channelList,
    secretList,
    appList
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const title = data?.id ? "编辑资源" : "新建资源";
  const initialValues = data?.id
    ? {
        ...data,
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;

console.log('sssssssssssssssss',data)

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        if(data?.id){
          values.version_id = data.version_id;
        }
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item name="app_id" label="应用类型" rules={[{ required: true, message: "请输入选择应用类型！" }]} >
        <Select>
            {appList.length &&
              appList.map((item) => {
                return (
                  <Option key={item.id} value={item.app_id}>
                    {item.name}
                  </Option>
                );
              })
            }
          </Select>
        </Form.Item>
        <Form.Item name="talent_id" label="达人ID" rules={[{ required: true, message: "请输入达人ID！" }]}>
          <Input disabled={data?.id} type='number' placeholder="请输入达人ID！"  />
        </Form.Item>
        <Form.Item name="work_id" label="作品ID" rules={[{ required: true, message: "请输入达人作品ID！" }]}>
          <Input  placeholder="请输入作品ID！"  />
        </Form.Item>
        <Form.Item
          name="channel_id"
          label="频道"
          rules={[{ required: true, message: "请输入频道ID！" }]}
        >
          <Select>
            {channelList.length &&
              channelList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="entity_id"
          label="实体对象"
          rules={[{ required: true, message: "请选择实体对象！" }]}
        >
          <Select>
            {secretList.length &&
              secretList.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              }) 
            }
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="name"
          label="实体名称"
          rules={[{ required: true, message: "请输入实体名称！" }]}
        >
          <Input placeholder="请输入名称"/>
        </Form.Item> */}
        <Form.Item
          name="talent_doc"
          label="挂载文案"
          rules={[{ required: true, message: "请输入挂载文案！" }]}
        >
          <Input placeholder="请输入挂载文案"
           maxLength={13}/>
        </Form.Item>
        {/* <Form.Item
          name="version_id"
          label="版本号"
          rules={[{ required: true, message: "请输入版本号！" }]}
        >
          <Input placeholder="请输入版本号" type='number'/>
        </Form.Item> */}
        <Form.Item
          name="order_num"
          label="排序数值"
        >
          <Input placeholder="请输入数值" type='number'/>
        </Form.Item>

        <Form.Item 
        name="disable" 
        label="审核状态">
          <Select>
            <Option value={0}>审核通过</Option>
            <Option value={1}>审核未通过</Option>
            <Option value={2}>审核中</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

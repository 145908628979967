import MediaList from '../pages/MediaList'
import EpisodeList from '../pages/EpisodeList'
import CharacterList from '../pages/CharacterList'
import UserList from '../pages/user/UserList'
import ChannelList from '../pages/ChannelList'
import TabList from '@/pages/TabList'
import SecretList from '@/pages/SecretList'
import QuestionList from '@/pages/QuestionList'
import AppList from '@/pages/AppList'
import MountList from '@/pages/MountList'
import AttachList from '@/pages/AttachList'
import { Navigate } from 'react-router-dom'
import {AppstoreOutlined, AlertOutlined,FundProjectionScreenOutlined, TeamOutlined, TagsOutlined, ApartmentOutlined, VideoCameraAddOutlined, BarsOutlined, UserAddOutlined, QuestionCircleOutlined, PaperClipOutlined} from '@ant-design/icons'

let token = window.sessionStorage.getItem('TOKEN')
if(!token||token===''||token==='undefined'){
    token = null
}
// console.log(token)
const customRoutes=[
    {
        index:true,
        hide:true,
        element:<Navigate to='/login' />
    },
    {
        path:'appList',
        name:'应用管理',
        icon:<AppstoreOutlined />,
        element:<AppList/>
    },
    {
        path:'userList',
        name:'用户管理',
        icon:<TeamOutlined />,
        element:<UserList/>
    },
    {
        path:'tabList',
        name:'标签管理',
        icon:<TagsOutlined />,
        element:<TabList/>
    },
    {
        path:'channelList',
        name:'频道管理',
        icon:<ApartmentOutlined />,
        element:<ChannelList/>
    },
    {
        path:'mediaList',
        name:'媒体管理',
        icon:<VideoCameraAddOutlined />,
        element:<MediaList/>
    },
    {
        path:'episodeList',
        name:'分集管理',
        icon:<BarsOutlined />,
        element:<EpisodeList/>
    },
    {
        path:'characterList',
        name:'人物管理',
        icon:<UserAddOutlined />,
        element:<CharacterList/>
    },
    {
        path:'secretList',
        name:'秘密管理',
        icon:<AlertOutlined />,
        element:<SecretList/>
    },
    {
        path:'attachList',
        name:'达人资源',
        icon:<FundProjectionScreenOutlined />,
        element:<AttachList/>
    },
    {
        path:'questionList',
        name:'趣答管理',
        icon:<QuestionCircleOutlined />,
        element:<QuestionList/>
    },
    {
        path:'mountList',
        name:'挂载管理',
        icon:<PaperClipOutlined />,
        element:<MountList/>
    }
]
export default customRoutes;

import  request  from '@utils/request'

export async function getCharacterList(data) {
  return request('api/v1/character', {
    method: 'GET',
    data,
  })
}

export async function getCharacter(id) {
  return request(`api/v1/character/${id}`, {
    method: 'GET',
  })
}

export async function createCharacter(data) {
  return request(`api/v1/character`, {
    method: 'POST',
    data,
  })
}

export async function updateCharacter(id, data) {
  return request(`api/v1/character/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function deleteCharacter(id) {
  return request(`api/v1/character/${id}`, {
    method: 'DELETE',
  })
}

import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
    PageHeader,
    Button,
    Modal,
    Row,
    Col,
    Input,
    Form,
    Image,
    Divider,
    Select,
    DatePicker,
  } from 'antd'
  import React, { useEffect, useState } from 'react'
  import useTag from '@/hooks/useTag'
  import useTableData from '@/hooks/useTableData'
  import BasicTable from '@/components/BasicTable'
  import UpdateForm from "./components/UpdateForm";
  import { formatParams,sortByField } from '@/utils/util'
  import moment from 'moment'
  import {
    getTabList,
    deleteTab,
    updateTab,
    createTab
  } from '@/services/tab'
  import { getChannelList } from '@/services/channel'
  import { getAppList } from '@/services/app'
  import commonData from '@/common/commonData'

  const TabList = () => {
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [currentRow, setCurrentRow] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)
    // const [partyList, setPartyList] = useState([])
    const [channelList, setChannelList] = useState([]);

    const { tagRender } = useTag()
    const [form] = Form.useForm()
    const { Option } = Select
    const { confirm } = Modal
    const { RangePicker } = DatePicker
    const {partyList} = commonData

    const columns = [
      {
        title: '标签ID',
        dataIndex: 'id',
        fixed: 'left',
        width: 80,
      },
      {
        title: '频道',
        dataIndex: 'channel_id',
        fixed: 'left',
        width: 80,
        render: (text, record) => {
            return channelList?.find(item => item.id === record.channel_id)?.name
        }
      },
      {
        title: '名称',
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: '应用类型',
        dataIndex: 'party',
        width: 200,
        render: (text, record) => {
            return partyList?.find(item => item.id === record.party)?.name
        }
      },
      {
        title: '标签序号',
        dataIndex: 'order_num',
        width: 100
      },
      {
        title: '状态',
        dataIndex: 'disable',
        width: 100,
        render: (text) => {
          return tagRender(text)
        }
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        width: 200,
        render: (text) => {
          return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
      },
      {
        title: '更新时间',
        dataIndex: 'update_time',
        width: 200,
        render: (text) => {
          return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        fixed: 'right',
        width: 150,
        valueType: 'option',
        render: (_, record) => (
          <>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setCurrentRow(record)
                setUpdateModalVisible(true)
              }}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              size="small"
              danger
              onClick={() => {
                handleDelete(record.id)
              }}
            >
              删除
            </Button>
          </>
        ),
      },
    ]

    useEffect(()=>{
        getChannelList({ page: 1, perPage: 1000 }).then(res => {
            setChannelList(res.list)
          })
    },[])

    function handleDelete(id) {
        confirm({
          title: "是否确认删除该标签",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          onOk() {
            console.log("OK", id);
            return deleteTab(id).then(() => {
              getList();
            });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
    }

    const { pagination, dataList, handleChange, getList } = useTableData(getTabList, {})
    function handleSearch(searchParams) {
      if (searchParams.time) {
        searchParams.start_time = Math.floor(moment(searchParams.time[0]).valueOf() / 1000)
        searchParams.end_time = Math.ceil(moment(searchParams.time[1]).valueOf() / 1000)
        delete searchParams.time
      }
      getList(searchParams)
    }

    async function handleUpdate(data){
        data.disable = Number(!data.disable)
        const params = formatParams(currentRow, data)
        if (params) {
            if (currentRow && currentRow.id) {
                await updateTab(currentRow.id, params)
            } else {
                await createTab(params)
            }
            await getList()
        }
        setUpdateModalVisible(false)
        setConfirmLoading(false)
    }

    const tableRender = () => {
      let arr = dataList
      return (
        <BasicTable
          columns={columns}
          pagination={pagination}
          handleChange={handleChange}
          dataList={arr}
        ></BasicTable>
      )
    }

    return (
      <div className="page">
        <PageHeader
            ghost={false}
            title="标签列表"
            extra={[
                <Button
                type="primary"
                size="medium"
                key="new"
                onClick={() => {
                    setCurrentRow(undefined)
                    setUpdateModalVisible(true)
                }}
                >
                新建
                </Button>,
            ]}
        >
          <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="标签ID" name="id" rules={[{ message: 'Please input ID!' }]}>
                  <Input placeholder="ID" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="名称" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="状态" name="disable">
                  <Select>
                    <Option value="0">可用</Option>
                    <Option value="1">不可用</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="time" label="媒体的创建时间范围">
                  <RangePicker showTime />
                </Form.Item>
              </Col> */}
              <Col span={4}>
                  <Form.Item label="频道" name="channel_id">
                      <Select>
                          {channelList &&
                          channelList.map((item, index) => {
                          return (
                              <Option key={"channelopt" + index} value={item.id}>
                              {item.name}
                              </Option>
                          );
                          })}
                      </Select>
                  </Form.Item>
              </Col>
              <Col span={4}>
                  <Form.Item label="应用类型" name="party">
                      <Select>
                          {partyList &&
                          partyList.map((item, index) => {
                          return (
                              <Option key={"opt" + index} value={item.id}>
                              {item.name}
                              </Option>
                          );
                          })}
                      </Select>
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>
                  <Form.Item label="排序数值" name="order_num">
                    <Input placeholder="数值" />
                  </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序方式" name="sort">
                  <Select>
                    <Option value='order_num.asc'>序号升序</Option>
                    <Option value='order_num.desc'>序号降序</Option>
                    <Option value='update_time.asc'>更新时间升序</Option>
                    <Option value='update_time.desc'>更新时间降序</Option>
                  </Select>
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </PageHeader>

        {tableRender()}
        {updateModalVisible && (
            <UpdateForm
            onSubmit={async (value) => {
                handleUpdate(value)
            }}
            onCancel={() => {
                setUpdateModalVisible(false)
                setCurrentRow(undefined)
            }}
            visible={updateModalVisible}
            confirmLoading={confirmLoading}
            data={currentRow}
            partyList={partyList}
            channelList={channelList}
            />
        )}
      </div>
    )
  }

  export default TabList

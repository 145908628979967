import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  PageHeader,
  Button,
  Modal,
  Drawer,
  Divider,
  Descriptions,
  Image,
  Input,
  Form,
  Row,
  Col,
  Select,
  message
} from 'antd'
import React, { useState, useRef } from 'react'
import UpdateForm from './components/UpdateForm'
import FileUploader from '@/components/FileUploader'
import useTag from '@/hooks/useTag'
import useTable from '@/hooks/useTable'
import { formatParams } from '@/utils/util'
import moment from 'moment'
import {
  getCharacterList,
  getCharacter,
  createCharacter,
  updateCharacter,
  deleteCharacter,
} from '@/services/character'
import styles from '../../global.less'

const { confirm } = Modal

const CharacterList = (props) => {
  const { hideHeader } = props
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [detail, setDetail] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  const [detailLoading, setDetailLoading] = useState({})
  const [importVisible, setImportVisible] = useState(false)

  const { tagRender } = useTag()
  const [form] = Form.useForm()

  const columns = [
    {
      title: '人物ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 80,
    },
    {
      title: '名称',
      dataIndex: 'name',
      fixed: 'left',
      width: 200,
    },
    {
      title: '生卒年',
      dataIndex: 'birth_death',
      width: 200,
    },
    {
      title: '一句话简介',
      dataIndex: 'aword',
      width: 200,
    },
    {
      title: '海报',
      dataIndex: 'poster',
      width: 100,
      render: (text) => {
        return <Image width={50} src={text} />
      },
    },
    {
      title: '剧照',
      dataIndex: 'still',
      width: 100,
      render: (text) => {
        return <Image width={50} src={text} />
      },
    },
    // {
    //   title: '人物描述',
    //   dataIndex: 'description',
    //   width: 300,
    //   // ellipsis: true,
    // },
    {
      title: '籍贯',
      dataIndex: 'birthplace',
      width: 100,
    },
    {
      title: '代表作',
      dataIndex: 'masterpiece',
      width: 300,
    },
    {
      title: '状态',
      dataIndex: 'disable',
      width: 100,
      render: (text) => {
        return tagRender(text)
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      width: 200,
      render: (text) => {
        return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '操作',
      dataIndex: 'option',
      fixed: 'right',
      width: 200,
      valueType: 'option',
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            loading={detailLoading[`data${record.id}`]}
            disabled={Object.keys(detailLoading).some((key) => detailLoading[key])}
            onClick={() => {
              console.log(record.id, detailLoading[record.id])
              setDetailLoading((detailLoading) => {
                const result = {
                  ...detailLoading,
                }
                result[`data${record.id}`] = true
                return result
              })
              setCurrentRow(record)
              getCharacter(record.id).then((res) => {
                setDetail(res)
                setShowDetail(true)
                setDetailLoading((detailLoading) => {
                  const result = {
                    ...detailLoading,
                  }
                  result[`data${record.id}`] = false
                  return result
                })
              })
            }}
          >
            详情
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record)
              setUpdateModalVisible(true)
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            danger
            onClick={() => {
              handleDelete(record.id)
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]

  const { tableRender, getList } = useTable(
    { columns, rowKey: 'id', ...props.tableOptions },
    getCharacterList,
  )

  function handleSearch(searchParams) {
    getList(searchParams)
  }

  async function handleUpdate(data) {
    if (confirmLoading) return
    data.disable = Number(!data.disable)
    data.is_default = Number(data.is_default)
    const params = formatParams(currentRow, data)
    console.log(params)
    if (params) {
      if (currentRow && currentRow.id) {
        await updateCharacter(currentRow.id, params)
      } else {
        await createCharacter(params)
      }
      await getList()
    }
    setUpdateModalVisible(false)
    setConfirmLoading(false)
  }

  function handleDelete(id) {
    console.log(id)
    confirm({
      title: '是否确认删除该用户',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      // content: 'Some descriptions',
      onOk() {
        console.log('OK', id)
        return deleteCharacter(id).then(() => {
          getList()
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const { Option } = Select
  const uploadOptions = {
    name: 'characters',
    action: '/api/v1/character/import',
    accept: '.xlsx,.xls',
    beforeUpload: (file) => {
      console.log('文件', file)
      if (file.size / 1024 / 1024 > 4) {
        message.error('文件大小不能超过4M！')
      }
      return false
    },
    cb: () => {
      getList()
      setImportVisible(false)
    },
  }

  return (
    <div className="page">
      {!hideHeader && (
        <PageHeader
          ghost={false}
          title="人物列表"
          extra={[
            <Button
              type="primary"
              size="medium"
              key="new"
              onClick={() => {
                setCurrentRow(undefined)
                setUpdateModalVisible(true)
              }}
            >
              新建
            </Button>,
            <Button
              type="primary"
              size="medium"
              key="import"
              onClick={() => {
                setImportVisible(true)
              }}
            >
              导入
            </Button>,
          ]}
        >
          <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
            <Row gutter={24}>
              <Col span={4}>
                <Form.Item label="人物ID" name="id" rules={[{ message: 'Please input ID!' }]}>
                  <Input placeholder="ID" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="名称" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="状态" name="disable">
                  <Select>
                    <Option value="0">可用</Option>
                    <Option value="1">不可用</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序数值" name="order_num">
                  <Input placeholder="数值" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="排序方式" name="sort">
                  <Select>
                    <Option value='order_num.asc'>序号升序</Option>
                    <Option value='order_num.desc'>序号降序</Option>
                    <Option value='update_time.asc'>更新时间升序</Option>
                    <Option value='update_time.desc'>更新时间降序</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields()
                  }}
                >
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
      {tableRender()}
      {updateModalVisible && (
        <UpdateForm
          onSubmit={async (value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setUpdateModalVisible(false)

            if (!showDetail) {
              setCurrentRow(undefined)
            }
          }}
          visible={updateModalVisible}
          confirmLoading={confirmLoading}
          data={currentRow}
        />
      )}
      {showDetail && (
        <Drawer
          width={600}
          visible={showDetail}
          onClose={() => {
            setCurrentRow(undefined)
            setDetail(null)
            setShowDetail(false)
          }}
          closable={false}
        >
          {detail && (
            <Descriptions title={detail.name} column={1} bordered labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="ID">{detail.id}</Descriptions.Item>
              <Descriptions.Item label="名称">{detail.name}</Descriptions.Item>
              <Descriptions.Item label="一句话简介">{detail.aword}</Descriptions.Item>
              <Descriptions.Item label="海报">
                <Image width={50} src={detail.poster} />
              </Descriptions.Item>
              <Descriptions.Item label="剧照">
                <Image width={50} src={detail.still} />
              </Descriptions.Item>
              <Descriptions.Item label="人物描述">
                <div
                  dangerouslySetInnerHTML={{ __html: detail.description }}
                  className={styles['html-wrapper']}
                />
              </Descriptions.Item>
              <Descriptions.Item label="籍贯">{detail.birthplace}</Descriptions.Item>
              <Descriptions.Item label="代表作">{detail.masterpiece}</Descriptions.Item>
              <Descriptions.Item label="状态">{tagRender(detail.disable)}</Descriptions.Item>
              <Descriptions.Item label="更新日期">
                {moment(detail.update_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Drawer>
      )}
      {importVisible && (
        <Modal
          title="导入规则说明"
          visible={importVisible}
          onCancel={() => {
            setImportVisible(false)
          }}
          footer={null}
        >
          <p>
            注意：excel 文件包含表头，必须严格按 name, aword,
            description,birth_death,birthplace,masterpiece 顺序。其中 birth_death
            在excel中需要设置成文本格式
            人物导入时，如数据库已存在相同名称的人物，则导入的人物会被过滤掉。
          </p>
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                setImportVisible(false)
              }}
              style={{ marginRight: '20px' }}
            >
              取消
            </Button>
            {/* <Upload {...uploadOptions} key="import">
              <Button type="primary" icon={<UploadOutlined />}>
                开始导入
              </Button>
            </Upload> */}
            <FileUploader {...uploadOptions} />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default CharacterList

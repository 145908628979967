import React, { useState, useCallback, useEffect, useRef } from 'react'
import useDebounce from '@/hooks/useDebounce'

const useTableData = (api, defaultParams) => {
  const [dataList, setDataList] = useState(null)
  const [tableLoading,setTableLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    pageSizeOptions: [50, 100, 200, 500],
    total: 0,
    showSizeChanger: true,
    showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条/总共 ${total} 条`,
  })

  const getList = useDebounce(
    async (params) => {
      setTableLoading(true)
    const res = await api({
      page: pagination.current,
      perpage: pagination.pageSize,
      ...params,
    })
    setTableLoading(false)
    setDataList(res.list)
    setPagination({
      ...pagination,
      current: res.page,
      pageSize: res.perpage,
      total: res.total,
    })
  },300)
   

  useEffect(() => {
    getList(defaultParams)
  }, [])

  const handleChange = (pageParams) => {
    let params = {
      ...defaultParams,
      page: pageParams.current,
      perpage: pageParams.pageSize,
    }
    getList(params)
  }
  return { dataList, getList, pagination, handleChange,tableLoading }
}
export default useTableData

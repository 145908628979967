export function formatParams(origin, params) {
  if (!params) return;
  if (!origin) return params;
  let result = {};
  Object.keys(params).forEach((key) => {
    if (params[key] !== origin[key]) {
      result[key] = params[key];
    }
  });
  if (Object.keys(result).length === 0) result = undefined;
  return result;
}

export function formatChannelTag(data) {
  let colorList = [
    "green",
    "blue",
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "cyan",
    "geekblue",
    "purple",
  ];
  if (data.length) {
    data.forEach((item, index) => {
      item.text = item.name;
      item.color = colorList[index % 11];
      item.key = item.id;
    });
  }

  return data;
}
// 数组根据字段进行排序，可选择升序或降序,并返回新数组
export function sortByField(arr, field, order = "asc") {
  if (!arr || !arr.length) return [];
  let result = arr.sort((a, b) => {
    if (order === "asc") {
      return a[field] - b[field];
    } else {
      return b[field] - a[field];
    }
  });
  return result;
}
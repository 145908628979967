import { LockOutlined, UserOutlined, PictureOutlined, CopyrightOutlined } from '@ant-design/icons'
import { Alert, message, Form, Input, Row, Col, Space,Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { ProFormText, LoginForm } from '@ant-design/pro-form'
import { getCaptcha, login } from '@services/login'
import styles from './index.less'
import { useNavigate,useLocation } from "react-router-dom";
import routes from '@/router/routes'

const LoginMessage = ({ content }) => (
  <Alert
  style={{
    marginBottom: 24,
  }}
  message={content}
  type="error"
  showIcon
  />
)

const Login = () => {
  // console.log('RENDER')
    const [userLoginState, setUserLoginState] = useState({})
    const [captcha, setCaptcha] = useState('')
    const [captchaId, setCaptchaId] = useState('')
    const navigate = useNavigate()
    const location= useLocation()

  const fetchCaptcha = async () => {
    try {
      let params = {
        id: '',
      }
      const arr = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      for (let i = 0; i < 32; i++) {
        params.id += arr[Math.round(Math.random() * (arr.length - 1))]
      }
      const code = await getCaptcha(params)
      var reader = new FileReader()
      reader.readAsDataURL(code)
      reader.onload = function (e) {
        // console.log(this.result)
        setCaptchaId(params.id)
        setCaptcha(this.result)
      }
    } catch {
      message.error('验证码获取失败，请重试！')
    }
  }
  useEffect(()=>{
    if (!captcha) {
      console.log('captcha',captcha)
      fetchCaptcha()
    }
  },[])

  const handleSubmit = async (values) => {
    try {
      // 登录
      console.log('login value',values)
      const res = await login({ ...values, captcha_id: captchaId })
      if(res.accessToken){
        window.sessionStorage.setItem('TOKEN', res.accessToken)
        window.sessionStorage.setItem('USER', values.email)

        /** 此方法会跳转到 redirect 参数所在的位置 */

        if (!navigate) return
        const { hash } = location
        // console.log('navigate location',location)
        let redirect  = hash||''
        redirect = redirect.replace('#', '')
        console.log('redirect',redirect,location) // 如果失败去设置用户错误信息
        if(redirect){
          navigate(redirect,{replace:true})
        }else if(routes.length && routes[1].path){
          navigate('/'+routes[1].path,{replace:true})
        }else{
          navigate('/mediaList',{replace:true})
        }
        // navigate('/appList',{replace:true})

        message.success('登录成功！')
      }else if(res.errorCode!==0){
        if(res.msg.includes('captcha')){
          fetchCaptcha()
        }else{
          message.error('账号或密码错误')
        }
      }
    } catch (error) {
      console.log('handleSubmit', error)
      message.error('登录失败，请重试！')
      fetchCaptcha()
    }
  }

  const { status, type: loginType } = userLoginState

  return (
    <div className={styles.container}>
      <div className={styles.lang} data-lang>
        {/* {SelectLang && <SelectLang />} */}
      </div>
      <div className={styles.content}>
        <Row>
          <Col offset={9} span={6}>
            <div className={styles.header}>
              <img src="/logo.png" alt=""/>
              <span >剧情管理</span>
            </div>
          </Col>
        </Row>

          {status === 'error' && <LoginMessage content="账户或密码错误" />}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={async (values) => {
            await handleSubmit(values)
          }}
        >
          <Form.Item
            name="email"
            wrapperCol={{ offset: 9, span: 6}}
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input size="large" prefix={<UserOutlined className={styles.prefixIcon} />}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 9, span: 6 }}
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password  size="large" prefix={<LockOutlined className={styles.prefixIcon} />} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 9, span: 6 }}>
              <Row gutter={6}>
                <Col span={12}>
                  <Form.Item
                    name="captcha_code"
                    rules={[{required: true, message: '请输入验证码！' }]}
                  >
                    <Input size="large" placeholder="请输入验证码" prefix={<PictureOutlined />} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <img
                    src={captcha}
                    onClick={() => {
                      fetchCaptcha()
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>

          <Form.Item wrapperCol={{ offset: 9, span: 6 }}>
            <Button size='large' type="primary" htmlType="submit"  className={styles['login-form-button']}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ margin: '48px 0 24px 0', textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' }}>
        <Space>
          <CopyrightOutlined />
          <span>{new Date().getFullYear()}</span>
          <span>Peter Studio wcaqrl@qq.com</span>
        </Space>
      </div>
    </div>
  )
}

export default Login

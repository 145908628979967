import  request  from '@utils/request'

export async function getUserList(data) {
  return request('api/v1/admin', {
    method: 'GET',
    data,
  })
}

export async function getUser(id) {
  return request(`api/v1/admin/${id}`, {
    method: 'GET',
  })
}

export async function createUser(data) {
  return request(`api/v1/admin`, {
    method: 'POST',
    data,
  })
}

export async function updateUser(id, data) {
  return request(`api/v1/admin/${id}`, {
    method: 'PUT',
    data,
  })
}

export async function deleteUser(id) {
  return request(`api/v1/admin/${id}`, {
    method: 'DELETE',
  })
}

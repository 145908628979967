import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Switch, message, Select } from "antd";
import moment from "moment";
import FunUploader from "@components/FunUploader";

const UpdateForm = (props) => {
  // console.log(props)
  const {
    data,
    onSubmit,
    onCancel,
    visible,
    confirmLoading,
  } = props;

  // const type = data?.id ? 'edit' : 'new'
  const [multiple, setMultiple] = useState('')
  const title = data?.id ? "编辑问题" : "新建问题";
  let options = data?JSON.parse(data.options):{}
  const { TextArea } = Input
  const initialValues = data?.id

    ? {
        ...data,
        disable: !Boolean(data.disable),
        selectA: options.A,
        selectB: options.B,
        selectC: options.C,
        selectD: options.D,
        answer : data?JSON.parse(data.answer):''
      }
    : {};

  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(()=>{
    if(data?.id && data.question_type ==2){
        setMultiple('multiple')
    }
  },[])

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        let options={}
        options.A = values.selectA
        options.B = values.selectB
        options.C = values.selectC
        options.D = values.selectD
        values.options = JSON.stringify(options)
        values.answer = JSON.stringify(values.answer)
        values.disable = values.disable ? 1 : 0
        values.reward = Number(values.reward)
        onSubmit(values);
        // form.resetFields()
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        message.warning("参数不正确～");
      });
  }

  return (
    <Modal
      width={640}
      bodyStyle={{
        padding: "32px 40px 48px",
      }}
      destroyOnClose
      title={title}
      confirmLoading={confirmLoading}
      visible={visible}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={initialValues}
        layout="horizontal"
        preserve={false}
      >
        {data?.id&&<Form.Item name="id" label="问题id">
          <Input disabled={data?.id} placeholder="请输入问题ID！" type="number" />
        </Form.Item>}
        <Form.Item
          name="name"
          label="名称"
          rules={[{ required: true, message: "请输入名称！" }]}
        >
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item name="media_id" label="媒体ID">
          <Input placeholder="请输入媒体ID" type={'number'} />
        </Form.Item>
        <Form.Item name="order_num" label="排序数值" type={'number'} >
          <Input placeholder="请输入排序数值" />
        </Form.Item>
        <Form.Item label="题目类型" name="question_type"
          rules={[{ required: true, message: "请选择题目类型！" }]}
        >
          <Select onChange={(val)=>{
            console.log('change',val)
            if(val === 1){
              setMultiple('')
            }else if(val === 2){
              setMultiple('multiple')
            }
          }}>
            <Option value={0}>未知</Option>
            <Option value={1}>单选题</Option>
            <Option value={2}>多选题</Option>
            <Option value={3}>判断题</Option>
          </Select>
        </Form.Item>
        <Form.Item label="A选项" name="selectA" 
        rules={[{ required: true, message: "请输入A选项！" }]}
        >
            <Input placeholder="A选项" />
        </Form.Item>
        <Form.Item label="B选项" name="selectB"
        rules={[{ required: true, message: "请输入B选项！" }]}
        >
            <Input placeholder="B选项" />
        </Form.Item>
        <Form.Item label="C选项" name="selectC">
            <Input placeholder="C选项" />
        </Form.Item>
        <Form.Item label="D选项" name="selectD">
            <Input placeholder="D选项" />
        </Form.Item>
        <Form.Item
        shouldUpdate
        label="答案"
        rules={[{ required: true, message: "请输入答案！" }]}
        >
          {()=>{
            return (
              <Form.Item name="answer"
              >
              <Select
                mode={multiple}
              >
                <Option value={'A'}>A</Option>
                <Option value={'B'}>B</Option>
                <Option value={'C'}>C</Option>
                <Option value={'D'}>D</Option>
              </Select>
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item label="答案解析"
        name="analysis"
        rules={[{ required: true, message: "请输入答案解析！" }]}
        >
            <TextArea placeholder="请输入解析" />
        </Form.Item>
        <Form.Item label="奖励分数" name="reward"
        rules={[{ required: true, message: "请输入奖励分数！" }]}
        >
            <Input placeholder="请输入分数" />
        </Form.Item>
        <Form.Item name="disable" label="是否可用" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateForm;

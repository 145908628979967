import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  PageHeader,
  Button,
  Modal,
  Drawer,
  Divider,
  Descriptions,
  Tag,
  Image,
  Tooltip,
  Input,
  Form,
  Row,
  Col,
  Select,
  Upload,
  message,
  DatePicker,
} from 'antd'
import React, { useState, useRef } from 'react'
import EpisodeList from '@pages/EpisodeList'
import UpdateForm from './components/UpdateForm.jsx'
import FileUploader from '@/components/FileUploader'
import useTag from '@/hooks/useTag'
import useTable from '@/hooks/useTable'
import { formatParams } from '@/utils/util'
import moment from 'moment'
import {
  getChannelList,
  createChannel,
  updateChannel,
  deleteChannel,
} from '@/services/channel'

const { confirm } = Modal

const ChannelList = () => {
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [currentRow, setCurrentRow] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const { tagRender } = useTag()
  const [form] = Form.useForm()
  const { Option } = Select

  const columns = [
    {
      title: '频道ID',
      dataIndex: 'id',
      fixed: 'left',
      width: 80,
    },
    {
      title: '名称',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: '状态',
      dataIndex: 'disable',
      width: 100,
      render: (text) => {
        return tagRender(text)
      },
    },
    {
      title: '父级id',
      dataIndex: 'pid',
    },
    {
      title: '父级名称',
      dataIndex: 'pid',
      render: (text,record) => {
        return dataList.find(item => item.id === record.pid)?.name || '--'
      },
      
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      width: 200,
      render: (text) => {
        return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      width: 200,
      render: (text) => {
        return moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '操作',
      dataIndex: 'option',
      fixed: 'right',
      width: 200,
      valueType: 'option',
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setCurrentRow(record)
              setUpdateModalVisible(true)
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            danger
            onClick={() => {
              handleDelete(record.id)
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]

  const { tableRender, getList, pagination, dataList } = useTable(
    { columns, rowKey: 'id' },
    getChannelList,
  )

  async function handleUpdate(data) {
    if (confirmLoading) return
    data.disable = Number(!data.disable)
    data.release_date = moment(data.release_date).format('X')
    const params = formatParams(currentRow, data)
    console.log(params)
    if (params) {
      if (currentRow && currentRow.id) {
        await updateChannel(currentRow.id, params)
      } else {
        await createChannel(params)
      }
      await getList()
    }
    setUpdateModalVisible(false)
    setConfirmLoading(false)
  }

  function handleDelete(id) {
    console.log(id)
    confirm({
      title: '是否确认删除该频道',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      // content: 'Some descriptions',
      onOk() {
        console.log('OK', id)
         return deleteChannel(id).then(() => {
           getList()
         })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  function handleSearch(searchParams) {
    if (searchParams.time) {
      searchParams.start_time = Math.floor(moment(searchParams.time[0]).valueOf() / 1000)
      searchParams.end_time = Math.ceil(moment(searchParams.time[1]).valueOf() / 1000)
      delete searchParams.time
    }
    getList(searchParams)
  }


  return (
    <div className="page">
      <PageHeader
        ghost={false}
        title="频道列表"
        extra={[
          <Button
            type="primary"
            size="medium"
            key="new"
            onClick={() => {
              setCurrentRow(undefined)
              setUpdateModalVisible(true)
            }}
          >
            新建
          </Button>,
        ]}
      >
        <Form name="basic" autoComplete="off" form={form} onFinish={handleSearch}>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="频道ID" name="id" rules={[{ message: 'Please input ID!' }]}>
                <Input placeholder="ID" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="名称" name="name">
                <Input placeholder="名称" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="状态" name="disable">
                <Select>
                  <Option value="0">可用</Option>
                  <Option value="1">不可用</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => {
                  form.resetFields()
                }}
              >
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </PageHeader>

      {tableRender()}
      {updateModalVisible && (
        <UpdateForm
          onSubmit={async (value) => {
            handleUpdate(value)
          }}
          onCancel={() => {
            setUpdateModalVisible(false)

            if (!showDetail) {
              setCurrentRow(undefined)
            }
          }}
          visible={updateModalVisible}
          confirmLoading={confirmLoading}
          data={currentRow}
          channelList={dataList}
        />
      )}
    </div>
  )
}

export default ChannelList

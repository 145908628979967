import  request  from '@utils/request'

export async function getQuestionList(data) {
    return request('api/v1/question', {
      method: 'GET',
      data,
    })
}

export async function deleteQuestion(id) {
    return request(`api/v1/question/${id}`, {
      method: 'DELETE',
    })
}


export async function updateQuestion(id, data) {
    return request(`api/v1/question/${id}`, {
      method: 'PUT',
      data,
    })
  }

    export async function createQuestion(data) {
    return request('api/v1/question', {
      method: 'POST',
      data,
    })
  }

import  request  from '@utils/request'

export async function getSecretList(data) {
    return request('api/v1/secret', {
      method: 'GET',
      data,
    })
}

export async function deleteSecret(id) {
    return request(`api/v1/secret/${id}`, {
      method: 'DELETE',
    })
}


export async function updateSecret(id, data) {
    return request(`api/v1/secret/${id}`, {
      method: 'PUT',
      data,
    })
  }

    export async function createSecret(data) {
    return request('api/v1/secret', {
      method: 'POST',
      data,
    })
  }

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { message, Modal } from "antd";


const LogoutModal = (props) => {
  const navigate = useNavigate();
  const {visible, changeLogoutModalVisible} = props

  useEffect(() => {
    changeLogoutModalVisible(visible)
  }, [visible, changeLogoutModalVisible]);

  const okHandle = () => {
    message.success("退出登录成功");
    changeLogoutModalVisible(false)
    window.sessionStorage.setItem('TOKEN', '')
    window.sessionStorage.setItem('USER', '')
    navigate('/login')
  };
  const cancelHandle = () => {
    changeLogoutModalVisible(false)
  };

  return (
    <Modal
      title="退出登录"
      visible={visible}
      onOk={okHandle}
      onCancel={cancelHandle}
      destroyOnClose={true}
      width={320}
    >
      <div>确定退出登录?</div>
    </Modal>
  )

};
export default LogoutModal;

import React, {  useEffect, useState } from 'react';
import BasicLayout, { MenuDataItem } from "@ant-design/pro-layout";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import RightContent from "../components/RightContent/RightContent";
import { Outlet } from 'react-router-dom'
import defaultSettings from '@/defaultSettings';
import customRoutes from '@/router/routes';
import styles from './BaseLayout.less'
// import logo from "../assets/logo.svg";
// import { menuIcons } from "../utils/icons";
// import { systemMenuList } from "./service";
import { Layout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
let route=customRoutes.filter((item)=>{return !item.hide})

const BaseLayout =()=>{
    const navigate  = useNavigate()
    const location = useLocation()
    const [auth ,setAuth] = useState(false)
    const [defaultSelected,setDefaultSelected]= useState('0')
    console.log('location',location)
    useEffect(()=>{
        let token = sessionStorage.getItem('TOKEN')
        // console.log('token',token)
        if(token){
            setAuth(true)
        }else{
            navigate('/login')
        }
        if(location.pathname === '/'){
            navigate('/login')
        }
        let val = [route.findIndex((item)=>{return '/'+item.path === location.pathname}).toString()]
        console.log('xxxxxxx',val)
        setDefaultSelected(val)
    },[location.pathname])

    const render = <>
        <Layout className={styles['layout']}>
            <Header className={styles['header']}>
                <div className={styles['logo']} >
                    <img src="/logo.png" alt=""/>
                  {/*http://img.funtv.bestv.com.cn/sdw?oid=6ed02c0e5d410ba67bdc80605d514015&w=0&h=0*/}
                </div>
                <span  className={styles['title']}>剧情管理</span>
                <RightContent  className={styles['rightContent']}/>
            </Header>
            <Layout>
            <Sider width={200} className="site-layout-background">
                <Menu
                mode="inline"
                defaultSelectedKeys={defaultSelected}
                selectedKeys={defaultSelected}
                style={{ height: '100%', borderRight: 0 }}
                // items={route&&(
                //         route.map((item,index) => {
                //             return {
                //                 key: index.toString(),
                //                 icon: item.icon,
                //                 label: item.name,
                //                 path: item.path,
                //                 onClick: ()=>{
                //                     navigate(item.path)
                //                 }
                //             }
                //         })
                //     )}
                >
                    {console.log('defaultSelected',defaultSelected)}
                    {route&&(
                        route.map((item,index) => {
                            return (
                                <Menu.Item
                                    key={index.toString()}
                                    onClick={()=>{
                                        navigate(item.path)
                                        }
                                    }
                                    icon={item.icon}
                                >
                                    <span>{item.name}</span>
                                </Menu.Item>
                            )

                        }
                        ))}
                </Menu>
            </Sider>
            <Layout >
                <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
                >
                    <Outlet/>
                </Content>
            </Layout>
            </Layout>
        </Layout>
    </>
    return (auth?render:<></>)
};

export default BaseLayout;
